import { Header, Footer } from '../components';

const PoliticaDePrivacidad = () => {
  return (
    <div className="h-screen flex flex-col justify-between">
      <Header />
      <div>
        <h1 className="text-4xl text-primary w-full text-center my-4 sm:my-8">
          Politica de Privacidad
        </h1>
        <div className="text-md sm:text-lg w-5/6 mx-auto">
          <>
            <ol>
              <li dir="ltr">
                <p dir="ltr">POLÍTICA DE PRIVACIDAD</p>
              </li>
            </ol>
            <p dir="ltr">
              ESTE DOCUMENTO CONTIENE LA POLÍTICA DE PRIVACIDAD QUE RIGE EL ACCESO AL SITIO WEB Y/O
              A LA APLICACIÓN MÓVIL “HUMANIN LAB” (EN ADELANTE, “HUMANIN LAB”), TITULARIDAD DE
              HUMANIN HAUS S.A.S. (EN ADELANTE, “HH”), Y LA UTILIZACIÓN DE CUALQUIERA DE LOS
              PRODUCTOS Y/O SERVICIOS DISPONIBLES O ACCESIBLES A TRAVÉS DE HL (LA “POLÍTICA DE
              PRIVACIDAD”). LA POLÍTICA DE PRIVACIDAD ES UN DOCUMENTO QUE CONSTITUYE UN CONTRATO
              VÁLIDO Y VINCULANTE ENTRE CUALQUIER USUARIO O POTENCIAL USUARIO Y/O TERCERO (“USUARIO”
              O, EN PLURAL, “USUARIOS”; JUNTO CON HH, LAS “PARTES”) PARA ACCEDER Y/O USAR HUMANIN
              LAB
            </p>
            <p dir="ltr">
              EL USUARIO TOMA CONOCIMIENTO Y ACEPTA IRREVOCABLEMENTE QUE AL INGRESAR Y REGISTRARSE
              EN HUMANIN LAB HA LEÍDO Y ACEPTADO EN SU TOTALIDAD LA PRESENTE POLÍTICA DE PRIVACIDAD,
              QUEDANDO OBLIGADO AL ESTRICTO CUMPLIMIENTO DE LOS MISMOS. CUALQUIER PERSONA QUE NO
              ACEPTE LA POLÍTICA DE PRIVACIDAD, LOS CUALES SON OBLIGATORIOS Y VINCULANTES PARA LOS
              USUARIOS, DEBERÁ ABSTENERSE DE INGRESAR A HUMANIN LAB Y/O CONTRATAR CUALQUIERA DE LOS
              PRODUCTOS Y/O SERVICIOS QUE OFRECE HUMANIN LAB (EN ADELANTE, LOS “SERVICIOS”).
            </p>
            <p dir="ltr">2. CONDICIONES GENERALES</p>
            <p dir="ltr">
              La Política de Privacidad describe el tipo de información y datos que HL y titular de
              Humanin LAB, recabará de los Usuarios así como el destino y usos que se le dará a los
              mismos, y los derechos y opciones que tienen los Usuarios.
            </p>
            <p dir="ltr">
              HL recolecta información de los Usuarios en el curso normal de su actividad y, en
              algunos casos, podría revelar tal información a terceros. Todo Usuario que se registre
              en Humanin LAB declara conocer y aceptar esta Política de Privacidad, prestando su
              consentimiento expreso para que su información personal sea utilizada por HL, como así
              también toda aquella información que HLrecolecta y/o adquiera por medio del uso y
              servicios de HL, en los términos y con el alcance que se describen a continuación.
            </p>
            <p dir="ltr">
              Esta Política de Privacidad es parte integrante de los Términos y Condiciones para el
              uso de Humanin LAB, los que aplican plenamente sobre las disposiciones que aquí se
              exponen. La aceptación de los Términos y Condiciones para el uso de Humanin Haus
              implica que el Usuario ha leído, entendido y acepta todos los términos y condiciones
              de esta Política de Privacidad, y de todas las políticas en particular allí incluidas.
            </p>
            <p dir="ltr">
              3. RESPONSABLE DEL TRATAMIENTO DE DATOS ¿Quién es el responsable de sus datos?
            </p>
            <ul>
              <li dir="ltr">
                <p dir="ltr">Titular: Humanin Haus S.A.S.</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Correo electrónico:
                  <a href="mailto:contacto@humaninhaus.com">contacto@humaninhaus.com</a>
                </p>
              </li>
            </ul>
            <br />
            <p dir="ltr">
              4. FINALIDAD DEL TRATAMIENTO DE LOS DATOS Y CATEGORÍAS DE DATOS TRATADOS
              <br />
              ¿Qué categorías de datos tratamos?
              <br />
              Dependiendo de la finalidad, HH trata las siguientes categorías de datos:
            </p>
            <p dir="ltr">
              • Datos identificativos, a saber: nombre, apellidos, dirección postal, dirección de
              correo electrónico, número de teléfono, Documento Nacional de Identidad,
              identificación de género, nacionalidad, y país de residencia.
            </p>
            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  Información de acceso y cuenta, incluyendo nombre de Usuario, contraseña e ID de
                  Usuario único.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Datos relacionados con las valoraciones de los Usuarios, derivadas de la
                  participación en pruebas ciegas, o la evaluación de los Servicios.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Datos de tráfico y localización (IP’s).</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Metadatos de comunicaciones electrónicas.</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Datos de información comercial.</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Datos económicos, financieros, y/o bancarios.
                  <br />
                  Los datos personales solicitados son obligatorios, de tal forma que la negativa a
                  suministrarlos supondrá la imposibilidad de llevar a cabo la prestación de los
                  Servicios y/o suscripciones contratados. En el futuro HL podría solicitar nuevas
                  categorías de datos personales, todo lo cual será oportunamente informado.
                  <br />
                  En caso que el Usuario facilite datos de terceros, manifiesta contar con el
                  consentimiento de los mismos y se compromete a trasladarle la información
                  contenida en esta cláusula, eximiendo a HL de cualquier responsabilidad en este
                  sentido. No obstante, HL podrá llevar a cabo las verificaciones para constatar
                  este hecho, adoptando las medidas de diligencia debida que correspondan, conforme
                  a la Normativa Aplicable (conforme es definida más adelante).
                  <br />
                  HL se reserva el derecho de realizar, en cualquier momento, cuantas pruebas y
                  verificaciones corporativas sean necesarias para garantizar la exactitud de los
                  datos facilitados, evitando con ello posibles duplicidades y/o errores en la
                  información facilitada
                  <br />
                  Los datos que en los formularios dispuestos por HL a través de la plataforma
                  aparezcan marcados con un asterisco (*), serán necesarios para cumplir con la
                  finalidad contractual o legal establecida.
                  <br />
                  Por lo tanto, si el Usuario no los facilitase, no será posible su registro en
                  Humanin LAB ni atender su solicitud.
                  <br />
                  ¿Con qué finalidad y durante cuánto tiempo trataremos sus datos personales?
                </p>
              </li>
            </ul>
            <p dir="ltr">
              HL, tratará los datos del Usuario, de manera manual y/o automatizada, para las
              siguientes finalidades específicas:
            </p>
            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  Gestionar su registro como Usuario en Humanin LAB. No obstante, en todo caso el
                  Usuario deberá verificar y validar su registro en Humanin LAB mediante la
                  introducción de su usuario y contraseña, prestando así su consentimiento.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Gestionar la contratación de los Servicios que realice a través de Humanin LAB,
                  así como la solicitud de facturas, su recogida y/o su expedición y remisión por
                  medios telemáticos siempre que la petición sea realizada por el propio interesado
                  o persona delegada por él, en cuyo caso deberá contar con la autorización
                  correspondiente. El titular de la información podrá revocar dicho consentimiento
                  en cualquier momento por escrito dirigido a contacto@humaninhaus.com
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Procesar tarjetas bancarias y/o pagos.</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Remitir (a través de correo electrónico, vía postal y/o SMS) comunicaciones
                  electrónicas con ofertas, promociones y noticias relacionadas con Humanin LAB (las
                  “Notificaciones”), salvo que el Usuario expresamente indique que no desea recibir
                  las Notificaciones.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Remitir información comercial y/o promocional, así como encuestas de valoración o
                  grado de satisfacción de los Usuarios con los Servicios (las “Encuestas”), salvo
                  que el Usuario expresamente indique que no desea recibir las Encuestas.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Realizar informes estadísticos anónimos respecto a los resultados obtenidos en la
                  prueba Test de Sesgos Implícitos de Diversidad
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Diseñar nuevos servicios que puedan resultar de interés a los Usuarios.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Elaborar un perfil comercial del Usuario y realizar acciones comerciales ajustadas
                  al mismo, utilizando sus datos derivados de la gestión de los Servicios adquiridos
                  (datos de navegación, hábitos de acceso, tráfico), salvo que el Usuario
                  expresamente indique lo contrario, se oponga y/o revoque su consentimiento a tal
                  fin.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Únicamente en caso de que el Usuario haya marcado la casilla correspondiente a fin
                  de prestar expresamente su consentimiento para ello, HL podrá elaborar un perfil
                  comercial recurriendo a información facilitada por terceros y podrá ofrecer
                  Servicios de acuerdo con sus intereses, así como mejorar su experiencia de
                  Usuario, pudiendo tomar decisiones automatizadas basadas en sus preferencias y/o
                  intereses personales, la fiabilidad y/o el comportamiento, Servicios adquiridos,
                  entre otros. En esta línea, HL podrá realizar ofertas concretas y enviar
                  comunicaciones comerciales adaptadas a su perfil. Caso contrario, el Usuario podrá
                  oponerse a ello y/o revocar su consentimiento en cualquier momento.
                </p>
              </li>
            </ul>
            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  Dar cumplimiento a las obligaciones legalmente establecidas, así como verificar el
                  cumplimiento de las obligaciones contractuales, incluida la prevención de fraude.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Llevar a cabo las actuaciones precisas para proteger los intereses vitales de los
                  Usuarios cuando así sea necesario, y/o el cumplimiento de resoluciones judiciales
                  así como también las medidas en ellas acordadas.
                  <br />
                  ¿Cuánto tiempo guardamos tus datos?
                  <br />
                  HL conservará los datos personales de los Usuarios únicamente durante el tiempo
                  necesario para la realización de las finalidades para las que fueron recogidos, y
                  siempre que los Usuarios no revoquen su consentimiento con anterioridad.
                  Posteriormente, en caso de ser necesario, HL mantendrá la información bloqueada
                  durante los plazos legalmente para cualquier acción que pueda devenir de su
                  tratamiento.
                  <br />
                  En los casos en que los Usuarios revoquen su consentimiento previamente otorgado
                  para algún tipo de tratamiento y/o ejerciten su derecho de supresión de sus datos
                  personales, rogamos tener en cuenta que HL se encuentra obligado a conservar dicha
                  información bloqueada y a disposición de los juzgados, tribunales y/o autoridades
                  competentes, durante los plazos de prescripción establecidos legalmente para
                  atender a las posibles responsabilidades de HL nacidas del tratamiento de datos.
                  <br />
                  Asimismo, en caso que el Usuario revocare el consentimiento previamente otorgado
                  para algún tratamiento y/o ejerciera su derecho de supresión de sus datos
                  personales, dichas acciones podrían implicar el cese de la prestación de los
                  Servicios al Usuario cuando tales datos fueran requeridos con carácter obligatorio
                  para tales fines.
                  <br />
                  ¿Qué medidas de seguridad implantamos para cuidar sus datos?
                  <br />
                  HL ha adoptado todas las medidas técnicas y de organización necesaria para
                  garantizar la seguridad e integridad de los datos de carácter personal que trate,
                  así como para evitar su pérdida, alteración y/o acceso por parte de terceros no
                  autorizados. No obstante lo anterior, el Usuario reconoce y acepta que las medidas
                  de seguridad en Internet no son inexpugnables.
                </p>
              </li>
            </ul>
            <p dir="ltr">
              En aquellos casos en los que HL cuente con prestadores de servicio y/o proveedores que
              se encuentren fuera de la Unión Europea, las trasferencias internacionales realizadas
              con ellos están plenamente garantizadas atendiendo a las normas establecidas por el
              Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016,
              y criterios de la Agencia Española de Protección de datos así como en las normativas
              aplicables a la protección de datos personales de los lugares de residencia de cada
              Usuario (la “Normativa Aplicable”).
            </p>
            <p dir="ltr">5. LEGITIMACIÓN</p>
            <p dir="ltr">¿Cuál es la legitimación para el tratamiento de sus datos?</p>
            <p dir="ltr">
              La legitimación para el tratamiento de los datos del Usuario por parte de HL para
              llevar a cabo el alta y registro del Usuario en Humanin Lab se encuentra en el
              consentimiento del interesado, solicitado para el caso concreto.
            </p>
            <p dir="ltr">
              Por su parte, la gestión de la contratación de Servicios a través de Humanin Lab,
              pago, facturación y envíos correspondientes, está legitimada por la propia ejecución
              del contrato de prestación de Servicios que contrata el Usuario al momento de
              registrarse como usuario de Humanin Lab.
            </p>
            <p dir="ltr">
              El tratamiento de los datos con el fin de remitir boletines electrónicos con
              Notificaciones, así como también las Encuestas, la elaboración de informes
              estadísticos anónimos respecto a los hábitos de acceso y la actividad desarrollada por
              los Usuarios en Humanin Lab y la elaboración de perfiles comerciales utilizando datos
              derivados de la gestión de los Servicios contratados con HL, se basan en el interés
              legítimo que tiene HH para llevar a cabo dichos tratamientos de acuerdo con la
              Normativa Aplicable.
            </p>
            <p dir="ltr">
              Los consentimientos obtenidos para las finalidades mencionadas son independientes, por
              lo cual el Usuario podrá revocar uno o varios de ellos sin afectar a los demás.
            </p>
            <p dir="ltr">
              Igualmente se podrá utilizar la información del Usuario para el cumplimiento de las
              diferentes obligaciones legales de HL.
            </p>
            <p dir="ltr">6. COMUNICACIONES DE DATOS</p>
            <p dir="ltr">¿A qué destinatarios se comunicarán sus datos?</p>
            <p dir="ltr">
              HL no cederá los datos personales de sus Usuarios a ningún tercero sin una base
              jurídica que legitime dicho tratamiento. En tal sentido, y de acuerdo a lo establecido
              por la Política de Privacidad y lo dispuesto por la Normativa Aplicable, HL podrá
              compartir la información recolectada de sus Usuarios en los siguientes supuestos:
            </p>
            <p dir="ltr">
              • Compañías de tarjetas de crédito y débito, agencias de informes de crédito y
              proveedores de servicios de control de fraude, para procesar pagos y (cuando sea
              necesario) realizar controles de fraude.
            </p>
            <p dir="ltr">
              • Proveedores de servicios externos que HL contrate para prestar servicios, como por
              ejemplo, para llevar a cabo iniciativas de marketing o realizar encuestas a Usuarios
              en su nombre.
            </p>
            <p dir="ltr">
              • Terceros, tales como despachos de abogados y tribunales, compañías aseguradoras,
              entidades intermediarias así como peritos y expertos, para exigir el cumplimiento o la
              aplicación de un contrato con usted o para defender nuestros intereses legítimos en el
              marco de acciones y procesos legales, administrativos, judiciales o extrajudiciales.
            </p>
            <p dir="ltr">
              • Terceros, tales como la policía y autoridades reguladoras, para proteger nuestros
              derechos, propiedades, o la seguridad de nuestros Usuarios, empleados y activos.
            </p>
            <p dir="ltr">
              • Cuando sea necesario para cumplir una obligación legal en cualquier jurisdicción.
            </p>
            <p dir="ltr">
              HL no comercializará los datos personales de sus Usuarios con terceros y sólo
              permitirá que terceros ajenos envíen comunicaciones comerciales si el Usuario ha
              prestado su consentimiento para ello.
            </p>
            <p dir="ltr">7. DERECHOS DE LOS USUARIOS</p>
            <p dir="ltr">¿Qué derechos tiene el titular de datos personales?</p>
            <p dir="ltr">
              De acuerdo con la Normativa Aplicable, los Usuarios pueden en cualquier momento y de
              manera gratuita, ejercer los siguientes derechos:
            </p>
            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  Revocar los consentimientos oportunamente otorgados: pueden retirar su
                  consentimiento en cualquier momento.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Obtener confirmación acerca de si en HL se están tratando datos personales que le
                  conciernen o no.
                </p>
              </li>
            </ul>
            <ul>
              <li dir="ltr">
                <p dir="ltr">
                  Acceder y/o consultar sus datos personales: tienen derecho a ser informados sobre
                  cómo tratamos los datos personales y el origen de los mismos.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Rectificar y/o modificar los datos inexactos o incompletos: tienen derecho a la
                  rectificación de los datos personales inexactos y a que se completen tus datos
                  personales. También pueden solicitar que se restrinja el tratamiento de dicha
                  información.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Solicitar la supresión de sus datos personales: cuando, entre otros motivos, los
                  datos ya no sean necesarios para los fines que fueron originalmente recogidos o se
                  revoque el consentimiento. HL cumplirá con las solicitudes de supresión sin
                  demoras y en cuanto nuestras capacidades lo permitan, salvo que se deba continuar
                  conservando la información por alguna de las siguientes razones: (i) el ejercicio
                  de la libertad de expresión e información; (ii) cumplir con una obligación legal
                  en cualquiera de las jurisdicciones donde HL desarrolla actividad, (iii) para el
                  cumplimiento de cualquier acción de interés público, (iv) para cumplir con el
                  objetivo de interés público, fines de investigación científica o histórica ; y/o
                  (v) para ejercer la defensa ante cualquier reclamo de orden legal.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Obtener de HL la limitación del tratamiento de los datos cuando se cumpla alguna
                  de las condiciones previstas en la Normativa Aplicable.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  En determinadas circunstancias y por motivos relacionados con su situación
                  particular al tratamiento de sus datos, los interesados podrán oponerse al
                  tratamiento de los mismos. HL dejará de tratar los datos, salvo por motivos
                  legítimos imperiosos, o por el ejercicio de la defensa de posibles reclamaciones.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Derecho a no ser objeto de decisiones automatizadas: si bien HL no toma decisiones
                  automatizadas, en el caso de ocurrir se podrá impugnar las decisiones
                  automatizadas adoptadas por parte de HL y requerir la intervención humana para
                  expresar su punto de vista.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Solicitar la portabilidad de sus datos personales.</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Objetar la promoción directa: los Usuarios pueden decidir si desean recibir o no
                  <br />
                  información de parte de HL.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Reclamar ante la autoridad de protección de datos personales de tu país de
                  residencia: cuando consideres que HH ha vulnerado los derechos que le son
                  reconocidos por la
                </p>
              </li>
            </ul>
            <p dir="ltr">
              Normativa Aplicable. A continuación, se listan las agencias de algunas jurisdicciones
              donde HH desarrolla actividad:
            </p>
            <p dir="ltr">España: Agencia española de Protección de Datos; www.agpd.es</p>
            <p dir="ltr">
              Argentina: Agencia de Acceso a la Información Pública; www.argentina.gob.ar/aaip
            </p>
            <p dir="ltr">
              Uruguay: Unidad Reguladora y de Control de Datos Personales;
              www.gob.uy/unidad-reguladora-control-datos-personales
            </p>
            <p dir="ltr">
              México: Instituto Nacional de Transparencia y Protección de Datos Personales;
              www.home.inai.org.mx
            </p>
            <p dir="ltr">
              Colombia: Superintendencia de Industria y Comercio / Registro Nacional de Base de
              Datos; www.sic.gov.co/protección-de-datos-personales
            </p>
            <p dir="ltr">
              Perú: Autoridad Nacional de Protección de Datos Personales; www.gob.pe/anpd
            </p>
            <p dir="ltr">
              Si Usted es residente de otra jurisdicción y desea conocer su agencia de
            </p>
            <p dir="ltr">protección de datos personales, no dude en consultarnos.</p>
            <div>
              <br />
            </div>
          </>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PoliticaDePrivacidad;
