import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Button } from '../../../components';
import { setUserFlowStep } from '../../../store/slices/userFlow';
import { FLOW_JDP_NO_WORDS_SELECTED_WARNING_SEEN } from '../../../consts/userFlowSteps';

const NoWordsSelectedWarning = () => {
  const { allSelectedWords } = useSelector((state) => state.juegoDePalabras);
  const dispatch = useDispatch();
  return (
    <div className="text-center h-screen gap-y-6 mx-auto w-5/6 sm:w-4/6">
      <Header />
      <h1 className="mt-24 font-semibold">
        {allSelectedWords.length === 0
          ? '¡No seleccionaste ninguna palabra!'
          : `Solo seleccionaste ${allSelectedWords.length} palabra`}
      </h1>
      <p className="text-red-500 font-semibold">
        Debes seleccionar al menos 2 de ellas para continuar el juego.
      </p>

      <Button
        partial
        customMargin="mx-auto mt-24"
        onClick={() => dispatch(setUserFlowStep(FLOW_JDP_NO_WORDS_SELECTED_WARNING_SEEN))}>
        Seleccionar palabras ahora
      </Button>
    </div>
  );
};

export default NoWordsSelectedWarning;
