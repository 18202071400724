import React from 'react';
import PropTypes from 'prop-types';

import logo from '../assets/logo.png';
import fullHeader from '../assets/background_header.svg';

const Header = ({ isFull = false }) => {
  return (
    <div className="w-full mb-6">
      {!isFull && (
        <img src={logo} alt="logo" draggable="false" className="w-32 md:w-48 mx-auto pt-6" />
      )}
      {isFull && <img src={fullHeader} alt="logo" draggable="false" className="w-full" />}
    </div>
  );
};

export default Header;

Header.propTypes = {
  isFull: PropTypes.bool
};
