import { configureStore } from '@reduxjs/toolkit';

// Reducers
import userFlow from './slices/userFlow';
import juegoDeRespuestaAutomatica from './slices/juegoDeRespuestaAutomatica';
import juegoDePalabras from './slices/juegoDePalabras';
import gameResults from './slices/gameResults';

export default configureStore({
  reducer: {
    userFlow,
    juegoDeRespuestaAutomatica,
    juegoDePalabras,
    gameResults
  }
});
