/* eslint-disable react/no-unescaped-entities */
import { Header, Footer } from '../components';

const SobreIATView = () => {
  return (
    <div className="h-screen flex flex-col justify-between">
      <Header />
      <div>
        <h1 className="text-4xl text-primary w-full text-center my-4 sm:my-8">
          Sobre el Test de Sesgos Implícitos (Implicit Automatic Bias -IAT)
        </h1>
        <div className="text-md sm:text-lg w-5/6 mx-auto">
          <>
            <p dir="ltr">
              Originalmente la prueba IAT fue diseñada para explorar las raíces inconscientes del
              sentimiento y pensamiento. Esta prueba fue construída con un propósito diferente:
              Ofrecer a las organizaciones y a las personas que trabajan en ellas una herramienta
              para acceder a un mayor conocimiento sobre sus preferencias y creencias que podrían
              obstaculizar la igualdad de oportunidades.
            </p>
            <p dir="ltr">
              Los sesgos implícitos son creencias profundas arraigadas en las personas y a las que
              muchas veces no somos capaces de acceder conscientemente.
            </p>
            <p dir="ltr">
              En los últimos años la palabra “sesgos inconscientes” ha tomado gran relevancia en la
              opinión pública y en el ámbito de las organizaciones. Muchas trabajan para que esos
              sesgos se vuelvan conscientes y así cambiar por ejemplo igualdad de oportunidades
              entre géneros, etnias, personas de diferentes edades, etc” ¿Pero cómo podemos medir la
              evolución de ese cambio o mejor aún si se han eliminado esos sesgos?
            </p>
            <p dir="ltr">
              Como dice Mahzarin Banaji, Richard Clarke Cabot Professor of Social Ethics at Harvard
              University, Founder of Outsmarting Human Minds (OHM), los sesgos son parte de la buena
              gente pero necesitamos explorarlos como parte importante de nuestra evolución.
            </p>
            <p dir="ltr">
              El juego de sesgos implícitos consiste en que el usuario en el menor tiempo posible
              pueda asignar una palabra o una imagen a una de las dos categorías que aparecen en
              pantalla. Es un juego cuyos resultados se obtienen a través de un algoritmo que toma
              el tiempo de respuesta del usuario. Si el usuario se retrasara en elegir las
              respuestas saliéndose del período de tiempo establecido, el test resultaría inválido
              para ese usuario.
            </p>
            <p dir="ltr">
              La validez del test resulta del periodo mínimo y máximo establecido en el algoritmo
              por el que se obtiene el resultado final.
            </p>
            <br />
            <p dir="ltr">Bibliografía</p>
            <p dir="ltr">
              La siguiente bibliografía es la recomendada por Harvard en su página implicit test.
            </p>
            <p dir="ltr">
              Bargh, J. A., Chaiken, S., &amp; Raymond, P., &amp; Hymes, C. (1996). The automatic
              evaluation effect: Unconditional automatic attitude activation with a pronunciation
              task. Journal of Experimental Social Psychology. Vol 32(1), 104-128.
            </p>
            <p dir="ltr">
              Blascovich, J., Ernst, J. M., Tomaka, J., Kelsey, R. M., et al. (1993). Attitude
              accessibility as a moderator of autonomic reactivity during decision making. Journal
              of Personality &amp; Social Psychology. Vol 64(2), 165 176.
            </p>
            <p dir="ltr">
              Chaiken, S., Bargh, J. A. (1993). Occurrence versus moderation of the automatic
              attitude activation effect: Reply to Fazio. Journal of Personality &amp; Social
              Psychology. Vol 64(5), 759-765.
            </p>
            <p dir="ltr">
              Crosby, F., Bromley, S., &amp; Saxe, L. (1980). Recent unobtrusive studies of Black
              and White discrimination and prejudice: A literature review. Psychological Bulletin,
              87, 546-563.
            </p>
            <p dir="ltr">
              DeBono, K. G., Snyder, M. (1995). Acting on one's attitudes: The role of a history of
              choosing situations. Personality &amp; Social Psychology Bulletin. Vol 21(6), 620-628.
            </p>
            <p dir="ltr">
              Devine, P. G. (1989). Stereotypes and prejudice: Their automatic and controlled
              components. Journal of Personality &amp; Social Psychology. Vol 56(1), 5-18.
            </p>
            <p dir="ltr">
              Dovidio, J. F., Kawakami, K., Johnson, C., Johnson, B., Howard, A. (1997). On the
              nature of prejudice: Automatic and controlled processes. Journal of Experimental
              Social Psychology. Vol 33(5), 510 540.
            </p>
            <p dir="ltr">
              Fabrigar, L. R., Priester, J. R., Petty, R. E., Wegener, D. T. (1998). The impact of
              attitude accessibility on elaboration of persuasive messages. Personality &amp; Social
              Psychology Bulletin. Vol 24(4), 339-352.
            </p>
            <p dir="ltr">
              Fazio, R. H. (1993). Variability in the likelihood of automatic attitude activation:
              Data reanalysis and commentary on Bargh, Chaiken, Govender, and Pratto (1992). Journal
              of Personality &amp; Social Psychology. Vol 64(5), 753-758.
            </p>
            <p dir="ltr">
              Fazio, R. H., Dunton, B. C. Categorization by race: The impact of automatic and
              controlled components of racial prejudice. Journal of Experimental Social Psychology.
              Vol 33(5), 451-470.
            </p>
            <p dir="ltr">
              Fazio, R. H., H., Paul M., Olney, T. J. (1984). Attitude accessibility following a
              self-perception process. Journal of Personality &amp; Social Psychology. Vol 47(2),
              277-286.
            </p>
            <p dir="ltr">
              Fazio, R. H., Jackson, Joni R., Dunton, Bridget C., Williams, Carol J. Variability in
              automatic activation as an unobstrusive measure of racial attitudes: A bona fide
              pipeline? Journal of Personality &amp; Social Psychology. Vol 69(6), 1013-1027.
            </p>
            <p dir="ltr">
              Fazio, R. H., Powell, M. C. (1997). On the value of knowing one's likes and dislikes:
              Attitude accessibility, stress, and health in college. Psychological Science. Vol
              8(6), 430-436.
            </p>
            <p dir="ltr">
              Fazio, R. H., Sanbonmatsu, D. M., Powell, M. C., Kardes, F. R. On the automatic
              activation of attitudes. Journal of Personality &amp; Social Psychology. Vol 50(2),
              229-238.
            </p>
            <p dir="ltr">
              Fazio, R. H., Williams, C. J. (1986). Attitude accessibility as a moderator of the
              attitude perception and attitude behavior relations: An investigation of the 1984
              presidential election. Journal of Personality &amp; Social Psychology. Vol 51(3),
              505-514.
            </p>
            <p dir="ltr">
              Greenwald, A. G., Banaji, M. R. (1995). Implicit social cognition: Attitudes,
              self-esteem, and stereotypes. Psychological Review. Vol 102(1), 4-27.
            </p>
            <p dir="ltr">
              Hodges, Sara D., Wilson, Timothy D. (1993). Effects of analyzing reasons on attitude
              change: The moderating role of attitude accessibility. Social Cognition. Vol 11(4),
              353-366.
            </p>
            <p dir="ltr">
              Houston, David A., Fazio, R. H. (1989). Biased processing as a function of attitude
              accessibility: Making objective judgments subjectively. Social Cognition. Vol 7(1),
              51-66.
            </p>
            <p dir="ltr">
              Kardes, Frank R., Sanbonmatsu, D. M., Voss, R. T., Fazio, R. H. (1986).
              Self-monitoring and attitude accessibility. Personality &amp; Social Psychology
              Bulletin. Vol 12(4), 468-474.
            </p>
            <p dir="ltr">
              Kawakami, K., Dion, K. L., Dovidio, John F. (1998). Racial prejudice and stereotype
              activation. Personality &amp; Social Psychology Bulletin. Vol 24(4), 407-416.
            </p>
            <p dir="ltr">
              Krosnick, J. A. (1989). Attitude importance and attitude accessibility. Personality
              &amp; Social Psychology Bulletin. Vol 15(3), 297-308.
            </p>
            <p dir="ltr">
              Lepore, L., Brown, R. (1997). Category and stereotype activation: Is prejudice
              inevitable? Journal of Personality &amp; Social Psychology. Vol 72(2), 275-287.
            </p>
            <p dir="ltr">
              Maio, G. R., Olson, J. M. (1995). The effect of attitude dissimulation on attitude
              accessibility. Social Cognition. Vol 13(2), 127-144.
            </p>
            <p dir="ltr">
              Powell, M. C., &amp; Fazio, R. H. (1984). Attitude accessibility as a function of
              repeated attitudinal expression. Personality &amp; Social Psychology Bulletin. Vol
              10(1), 139-148.
            </p>
            <p dir="ltr">
              Roese, N. J., Olson, J. M. (1994). Attitude importance as a function of repeated
              attitude expression. Journal of Experimental Social Psychology. Vol 30(1), 39-51.
            </p>
            <p dir="ltr">
              Roskos-Ewoldsen, D. R., Fazio, R. H. (1992). On the orienting value of attitudes:
              Attitude accessibility as a determinant of an object's attraction of visual attention.
              Journal of Personality &amp; Social Psychology. Vol 63(2), 198-211.
            </p>
            <p dir="ltr">
              Schuette, R. A., Fazio, R. H. (1995). Attitude accessibility and motivation as
              determinants of biased processing: A test of the MODE model. Personality &amp; Social
              Psychology Bulletin. Vol 21(7), 704-710.
            </p>
            <p dir="ltr">
              Smith, E. R., Fazio, R. H., Cejka, M. A. (1996). Accessible attitudes influence
              categorization of multiply categorizable objects. Journal of Personality &amp; Social
              Psychology. Vol 71(5), Nov 1996, 888-898.
            </p>
            <p dir="ltr">
              von Hippel, W., Sekaquaptewa, D., Vargas, P. (1997). The linguistic intergroup bias as
              an implicit indicator of prejudice. Journal of Experimental Social Psychology. Vol
              33(5), 490-509.
            </p>
            <p dir="ltr">
              Wittenbrink, B., Judd, C. M., Park, B. (1997). Evidence for racial prejudice at the
              implicit level and its relationship with questionnaire measures. Journal of
              Personality &amp; Social Psychology. Vol 72(2), 262-274.
            </p>
            <p dir="ltr">Creencias</p>
            <p dir="ltr">
              Banaji, M. R., &amp; Hardin, C. D. (1996). Automatic stereotyping. Psychological
              Science. Vol 7(3), 136-141.
            </p>
            <p dir="ltr">
              Banaji, M. R., Hardin, C., &amp; Rothman, A. J. (1993). Implicit stereotyping in
              person judgment. Journal of Personality &amp; Social Psychology. Vol 65(2), 272-281.
            </p>
            <p dir="ltr">
              Bargh, J. A., C, M., &amp; Burrows, L. (1996). Automaticity of social behavior: Direct
              effects of trait construct and stereotype activation on action. Journal of Personality
              &amp; Social Psychology. Vol 71(2), 230-244.
            </p>
            <p dir="ltr">
              Blair, I. V., Banaji, M. R. (1996). Automatic and controlled processes in stereotype
              priming. Journal of Personality &amp; Social Psychology. Vol 70(6), 1142-1163.
            </p>
            <p dir="ltr">
              Chen, M., Bargh, J. A. (1997). Nonconscious behavioral confirmation processes: The
              self-fulfilling consequences of automatic stereotype activation. Journal of
              Experimental Social Psychology. Vol 33(5), 541-560.
            </p>
            <p dir="ltr">
              Devine, P. G. (1989). Stereotypes and prejudice: Their automatic and controlled
              components. Journal of Personality &amp; Social Psychology. Vol 56(1), 5-18.
            </p>
            <p dir="ltr">
              Dijksterhuis, A., van Knippenberg, A. (1996). The knife that cuts both ways:
              Facilitated and inhibited access to traits as a result of stereotype activation.
              Journal of Experimental Social Psychology. Vol 32(3), 271-288.
            </p>
            <p dir="ltr">
              Dovidio, J. F., Evans, N., Tyler, R. B. (1986). Racial stereotypes: The contents of
              their cognitive representations. Journal of Experimental Social Psychology. Vol 22(1),
              22-37.
            </p>
            <p dir="ltr">
              Gaertner, S. L., &amp; McLaughlin, J. P. (1983). Racial stereotypes: Associations and
              ascriptions of positive and negative characteristics. Social Psychology Quarterly, 46,
              23-30.
            </p>
            <p dir="ltr">
              Gilbert, D. T., Hixon, J. G. (1991). The trouble of thinking: Activation and
              application of stereotypic beliefs. Journal of Personality &amp; Social Psychology.
              Vol 60(4), 509-517.
            </p>
            <p dir="ltr">
              Greenwald, A. G., Banaji, M. R. (1995). Implicit social cognition: Attitudes,
              self-esteem, and stereotypes. Psychological Review. Vol 102(1), 4-27.
            </p>
            <p dir="ltr">
              Kawakami, K., Dion, K. L., Dovidio, John F. (1998). Racial prejudice and stereotype
              activation. Personality &amp; Social Psychology Bulletin. Vol 24(4), 407-416.
            </p>
            <p dir="ltr">
              Lepore, L., Brown, R. (1997). Category and stereotype activation: Is prejudice
              inevitable? Journal of Personality &amp; Social Psychology. Vol 72(2), 275-287.
            </p>
            <p dir="ltr">
              Macrae, C. N., Bodenhausen, G. V., Milne, A. B., Thorn, T. M. J., Castelli, L. (1997).
              On the activation of social stereotypes: The moderating role of processing objectives.
              Journal of Experimental Social Psychology. Vol 33(5), 471-489.
            </p>
            <p dir="ltr">
              Macrae, C. N., Milne, A. B., Bodenhausen, G. V. (1994). Stereotypes as energy-saving
              devices: A peek inside the cognitive toolbox. Journal of Personality &amp; Social
              Psychology. Vol 66(1), 37-47.
            </p>
            <p dir="ltr">
              Manis, M., Paskewitz, J., Cotler, S. (1986). Stereotypes and social judgment. Journal
              of Personality &amp; Social Psychology. Vol 50(3), 461-473.
            </p>
            <p dir="ltr">
              Pendry, L. F., &amp; Macrae, C. N. (1994). Stereotypes in mental life: The case of the
              motivated but thwarted tactician. Journal of Experimental Social Psychology, 30,
              303-325.
            </p>
            <p dir="ltr">
              Pratto, F., &amp; Bargh, J. A. (1991). Stereotyping based on apparently individuating
              information: Trait and global components of sex stereotypes under attention overload.
              Journal of Experimental Social Psychology, 27, 26-47.
            </p>
            <p dir="ltr">
              Stangor, C. (1988). Stereotype accessibility and information processing. Personality
              &amp; Social Psychology Bulletin. Vol 14(4), 694-708.
            </p>
            <p dir="ltr">
              Stapel, D. A., Koomen, W. (1998). When stereotype activation results in (counter)
              stereotypical judgments: Priming stereotype-relevant traits and exemplars. Journal of
              Experimental Social Psychology. Vol 34(2), 136-163.
            </p>
            <p dir="ltr">
              van Knippenberg, A., Dijksterhus, A. (1996). A posteriori stereotype activation: The
              preservation of stereotypes through memory distortion. Social Cognition. Vol 14(1),
              21-53.
            </p>
            <p dir="ltr">Formación de Impresiones</p>
            <p dir="ltr">
              Higgins, E. T., Rholes, W. S., &amp; Jones, C. R. (1977). Category accessibility and
              impression formation. Journal of Experimental Social Psychology, 13, 141-154.
            </p>
            <p dir="ltr">
              Levy, S. R., Dweck, C. S. (1998). Trait- versus process-focused social judgment.
              Social Cognition. Vol 16(1), 151-172.
            </p>
            <p dir="ltr">Percepción de Personas</p>
            <p dir="ltr">
              Anderson, C. A., &amp; Sedikides, C. (1991). Thinking about people: Contributions of a
              typological alternative to associationistic and dimensional models of person
              perception. Journal of Personality &amp; Social Psychology. Vol 60(2), 203-217.
            </p>
            <p dir="ltr">
              Bargh, J. A. (1989). Conditional automaticity: Varieties of automatic influence in
              social perception and cognition. Uleman, James S. (Ed)., Bargh, J. A. (Ed)., et al.
              Unintended thought. (pp. 3-51). New York, NY, USA: Guilford Press. xxvi, 481 pp.
            </p>
            <p dir="ltr">
              Bargh, J. A., Lombardi, W. J., Higgins, E. T. (1988). Automaticity of chronically
              accessible constructs in person * situation effects on person perception: It's just a
              matter of time. Journal of Personality &amp; Social Psychology. Vol 55(4), 599-605.
            </p>
            <p dir="ltr">
              Levy, S. R., Dweck, C. S. (1998). Trait- versus process-focused social judgment.
              Social Cognition. Vol 16(1), 151-172.
            </p>
            <p dir="ltr">
              Pratto, F., &amp; Bargh, J. A. (1991). Stereotyping based on apparently individuating
              information: Trait and global components of sex stereotypes under attention overload.
              Journal of Experimental Social Psychology, 27, 26-47.
            </p>
            <p dir="ltr">Auto Percepción</p>
            <p dir="ltr">
              Fazio, R. H., H., Paul M., Olney, T. J. (1984). Attitude accessibility following a
              self-perception process. Journal of Personality &amp; Social Psychology. Vol 47(2),
              277-286.
            </p>
            <p dir="ltr">
              Greenwald, A. G., Banaji, M. R. (1995). Implicit social cognition: Attitudes,
              self-esteem, and stereotypes. Psychological Review. Vol 102(1), 4-27.
            </p>
            <p dir="ltr">Misceláneos</p>
            <p dir="ltr">
              Bargh, J. A. (1997). The automaticity of everyday life. Wyer, Robert S. Jr. (Ed)., et
              al. (1997). The automaticity of everyday life: Advances in social cognition, Vol. 10.
              (pp. 1-61). Mahwah, NJ, USA: Lawrence Erlbaum Associates, Inc. , Publishers. viii, 258
              pp.
            </p>
            <p dir="ltr">
              Bargh, J. A., Chaiken, Shelly., Govender, Rajen., Pratto, Felicia. (1992). The
              generality of the automatic attitude activation effect. Journal of Personality &amp;
              Social Psychology. Vol 62(6), 893-912.
            </p>
            <p dir="ltr">
              Bassili, J. N. (1995). Response latency and the accessibility of voting intentions:
              What contributes to accessibility and how it affects vote choice. Personality &amp;
              Social Psychology Bulletin. Vol 21(7), 686-695.
            </p>
            <p dir="ltr">
              Borkenau, P., Liebler, A. (1992). Trait inferences: Sources of validity at zero
              acquaintance. Journal of Personality &amp; Social Psychology. Vol 62(4), 645-657.
            </p>
            <p dir="ltr">
              Fiske, S. T., Taylor, S. E. (1991). Social cognition (2nd ed. ). McGraw-Hill series in
              social psychology. New York, NY, USA: Mcgraw-Hill Book Company. xviii, 717.
            </p>
            <p dir="ltr">
              Fridlund, A. J. (1991). Sociality of solitary smiling: Potentiation by an implicit
              audience. Journal of Personality &amp; Social Psychology. Vol 60(2), 229-240.
            </p>
            <p dir="ltr">
              Gilbert, Daniel T., Krull, D. S. (1988). Seeing less and knowing more: The benefits of
              perceptual ignorance. Journal of Personality &amp; Social Psychology. Vol 54(2),
              193-202.
            </p>
            <p dir="ltr">
              Greenwald, A. G., McGhee, D. E., &amp; Schwartz, J. L. K. (1998). Measuring individual
              differences in implicit cognition: The implicit association test. Journal of
              Personality and Social Psychology. Vol 74, 1464-1480.
            </p>
            <p dir="ltr">
              Lee, Y., Ottati, V. (1995). Perceived in-group homogeneity as a function of group
              membership salience and stereotype threat. Personality &amp; Social Psychology
              Bulletin. Vol 21(6), 610-619.
            </p>
            <p dir="ltr">
              Niedenthal, P. M. (1990). Implicit perception of affective information. Journal of
              Experimental Social Psychology. Vol 26(6), 505-527.
            </p>
            <p dir="ltr">
              Perdue, C. W., &amp; Dovidio, J. F., Gurtman, M. B., &amp; Tyler, R. B. (1990). "Us"
              and "them": Social categorization and the process of intergroup bias. Journal of
              Personality and Social Psychology, 59, 475-486.
            </p>
            <p dir="ltr">
              Perdue, C. W., &amp; Gurtman, M. B. (1990). Evidence for the automaticity of ageism.
              Journal of Experimental Social Psychology, 26, 199-216.
            </p>
            <p dir="ltr">
              Ross, M., Newby-Clark, I. R. (1998). Construing the past and future. Social Cognition.
              Vol 16(1), 133-150.
            </p>
            <p dir="ltr">
              Rothbart, M., Sriram, N., Davis-Stitt, C. (1996). The retrieval of typical and
              atypical category members. Journal of Experimental Social Psychology. Vol 32(4),
              309-336.
            </p>
            <p dir="ltr">
              Stroessner, S. J. (1996). Social categorization by race or sex: Effects of perceived
              non-normalcy on response times. Social Cognition. Vol 14(3), 247-276.
            </p>
            <p dir="ltr">
              Wegner, D. M., Bargh, J. A. (1998). Control and automaticity in social life. Gilbert,
              Daniel T. (Ed)., Fiske, Susan T. (Ed)., et al. (1998). The handbook of social
              psychology, Vol. 2 (4th ed. ). (pp. 446-496). Boston, MA, USA: Mcgraw-Hill. x, 1085
              pp.
            </p>
            <p dir="ltr">
              Woike, B. A. (1995). Most-memorable experiences: Evidence for a link between implicit
              and explicit motives and social cognitive processes in everyday life. Journal of
              Personality &amp; Social Psychology. Vol 68(6), 1081-1091.
            </p>
          </>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SobreIATView;
