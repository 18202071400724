import React from 'react';
// import radioButtonIcon from '../assets/icons/radio-button.svg';

// eslint-disable-next-line react/prop-types
const Radio = ({ handleChange, value, name, text, checked, disabled }) => {
  return (
    <div className={`grid grid-cols-1 mb-1 w-full  ${disabled && 'opacity-30'}`}>
      <div className="relative">
        <input
          className="default:bg-circle visited:bg-circle active:bg-circle  hover:bg-circle checked:bg-circle"
          type="radio"
          name={name}
          value={value}
          onChange={handleChange}
          checked={checked}
          id={value}
        />

        <label htmlFor={value}>
          <span className="pl-2"> {text} </span>
        </label>

        {/* <img className="mr-4" width={25} height={25} src={radioButtonIcon} /> */}
      </div>
    </div>
  );
};

export default Radio;
