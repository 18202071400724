export const categories = [
  {
    id: 1,
    name: 'Femenino',
    text: '<b>Imágenes</b> que coincidan con el estereotipo cultural mujer'
  },
  {
    id: 2,
    name: 'Masculino',
    text: '<b>Imágenes</b> que coincidan con el estereotipo cultural varón'
  },
  {
    id: 3,
    name: 'C-Level',
    text: '<b>Palabras</b>: CEO-Presidente-Vicepresidente-Director- Gerente General'
  },
  {
    id: 4,
    name: 'Colaborador/a',
    text: '<b>Palabras</b>: Junior- Asistente- Supervisor-Jefe- Manager- Analista'
  },
  {
    id: 5,
    name: 'Hard',
    text: '<b>Palabras</b>: Competitivo - Firme- Ambicioso- Pragmático- Visionario- Disruptivo'
  },
  {
    id: 6,
    name: 'Soft',
    text: '<b>Palabras</b>: Protector- Emocional- Sensible- Inclusivo- Colaborativo- Empático- Flexible'
  }
];
