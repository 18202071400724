import { useRoutes } from 'react-router-dom';

// Views
import PoliticaDePrivacidadView from './views/PoliticaDePrivacidad';
import SobreIATView from './views/SobreIAT';
import GameView from './views/GameView';
import NotFoundView from './views/NotFoundView';
import PreguntasFrecuentes from './views/PreguntasFrecuentes';

export default function Router() {
  return useRoutes([
    {
      path: '/game/:id',
      element: <GameView />
    },
    {
      path: 'politica-de-privacidad',
      element: <PoliticaDePrivacidadView />
    },
    {
      path: 'sobre-iat',
      element: <SobreIATView />
    },
    {
      path: 'preguntas-frecuentes',
      element: <PreguntasFrecuentes />
    },
    {
      path: '*',
      element: <NotFoundView />
    }
  ]);
}
