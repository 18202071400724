import PropTypes from 'prop-types';

const SelectedWords = ({ title, words }) => {
  return (
    <>
      <h1 className="text-center text-principal text-lg sm:text-2xl mb-12 sm:my-12">{title}</h1>
      <main className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 sm:my-0">
        {words.map((word) => (
          <p
            className="uppercase border text-center border-secondary font-bold bg-gray-50 rounded-md p-3 sm:p-4 whitespace-nowrap text-xs md:text-lg text-secondary"
            key={word}>
            {word}
          </p>
        ))}
      </main>
    </>
  );
};

export default SelectedWords;

SelectedWords.propTypes = {
  words: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.element.isRequired
};
