import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Header, LayoutDecorativeFigure } from '../components';
import { setUserFlowStep } from '../store/slices/userFlow';
import { FLOW_STARTED } from '../consts/userFlowSteps';

function NewUsersLanding() {
  const dispatch = useDispatch();

  const handleContinuarButtonClick = () => {
    dispatch(setUserFlowStep(FLOW_STARTED));
  };

  return (
    <div className="min-h-screen flex flex-col justify-between">
      <Header />

      <LayoutDecorativeFigure>
        <div className="text-left text-secondary px-16 md:p-8 w-full sm:px-0 md:w-4/6 mx-auto my-12 sm:my-0">
          <h3
            className="my-3 md:mt-7   font-semibold"
            style={{
              fontFamily: 'Urbanist',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '24px',
              color: '#E53D3D'
            }}>
            Bienvenidos/as a Human inLAB
          </h3>
          <h3 className="my-3 md:mt-7 ">
            Tu organización te ha invitado a participar para conocer su{' '}
            <strong>
              Índice de Permeabilidad de la Cultura de la Organización a la Diversidad, Equidad e
              Inclusión (DEI)
            </strong>
            . Un estándar de medición que ayuda a las organizaciones a dimensionar si DEI es o no
            parte del ADN de su cultura.
          </h3>
          <h3 className="my-3 md:mt-7 ">
            A continuación te pediremos que realices{' '}
            <strong style={{ color: '#E53D3D' }}>dos juegos</strong> que no te llevarán más de 15
            minutos en total. <strong>¿Lo mejor?</strong> es que al finalizar obtendrás un reporte
            individual con tus resultados
          </h3>

          <h3 className="">
            <strong>¿En qué consisten los juegos?</strong>
          </h3>
          <h3 className="mb-3 md:mb-7 ">
            Medimos de forma lúdica asociaciones implícitas y percepción de cultura organizacional.
            Más adelante te explicaremos cómo funciona cada uno
          </h3>

          <h3 className="">
            <strong>¿Cuántos juegos tendré que realizar?</strong>
          </h3>
          <h3 className="mb-3 md:mb-7 ">Dos juegos para completar todo el proceso</h3>

          <h3 className="">
            <strong>¿Hay ganadores y perdedores?</strong>
          </h3>
          <h3 className="mb-3 md:mb-7 ">No. Los juegos no son competitivos</h3>

          <h3 className="">
            <strong>¿Cuál es el resultado ideal?</strong>
          </h3>
          <h3 className="mb-3 md:mb-7 ">
            No hay resultados ideales al finalizar los juegos. Ningún resultado es bueno o malo, son
            simplemente resultados que te proporcionarán información sobre tí
          </h3>

          <h3 className="">
            <strong>¿Quién podrá ver mi resultado?</strong>
          </h3>
          <h3 className="mb-3 md:mb-7 ">
            Sólo tú podrás ver tus resultados. La organización verá sólo resultados globales sin
            posibilidad de individualizar las respuestas.
          </h3>

          <h3 className="">
            <strong>¿Cuánto tiempo me llevará hacer los dos juegos?</strong>
          </h3>
          <h3 className="mb-3 md:mb-7 ">El proceso completo te llevará 15 minutos como máximo.</h3>

          <div className="my-8 py-4">
            <Button onClick={() => handleContinuarButtonClick()}>Continuar</Button>
          </div>
        </div>
      </LayoutDecorativeFigure>
    </div>
  );
}

export default NewUsersLanding;
