import React, { useEffect } from 'react';

import { Footer, Header } from '../../../components';
import { cards } from '../../../consts/juegoDePalabras/consts';
import computer from '../../../assets/computer.png';

import FormJuegoDePalabras from './FormJuegoDePalabras';

const FormAndIntroJuegoDePalabras = () => {
  // Scroll up to top on page load (mainly for mobile)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full h-screen flex flex-col justify-between items-center text-principal">
      <Header isFull={true} />
      <div className="text-left px-16 w-full my-12 sm:px-0 md:w-4/6 md:p-8 md:px-28 sm:mt-0">
        <h1 className="text-2xl text-principal font-semibold">Juego de Palabras</h1>
        <h2 className="text-xl uppercase text-softerRed font-semibold pt-10">
          Palabras que definen cultura
        </h2>
        <p className="my-5">
          Este juego tiene como finalidad saber cómo y qué define a la cultura de una organización.
          Cada palabra de este juego debería responder estas preguntas: ¿Quiénes somos? ¿Cómo nos
          comportamos?
          <br />
          <br />
          Te contamos que este juego sólo puedes realizarlo de una vez, es decir, no puedes
          guardarlo y finalizar mas tarde. Su carácter es anónimo.
        </p>
      </div>

      <div className="w-full sm:w-5/6 ">
        <div className="mb-20 rounded-3xl px-16 md:bg-wordGameBack md:border-2">
          <h2 className="text-xl md:text-2xl font-semibold text-center my-10 h-100">
            ¿En qué consiste este juego?
          </h2>
          <div className="md:grid md:grid-cols-2 mb-20">
            <div className="mt-10">
              <img
                src={computer}
                draggable={false}
                className="max-w-full mb-4"
                style={{ width: 'auto', height: 'auto' }}
                alt="computer"
              />
            </div>
            <div>
              <h3 className="text-lg">Deberás asociar en el menor tiempo posible:</h3>
              <div className="my-5 w-full h-full mx-auto md:grid md:grid-cols-2 gap-x-20">
                {cards.map((card) => (
                  <div key={card.title} className="text-center">
                    <h1 className="text-xl font-semibold">{card.title}</h1>
                    <p className="mt-6 text-secondary">{card.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-left px-16 w-full my-12 sm:px-0 md:w-4/6 md:p-8 md:px-28 sm:mt-0">
        <div className="text-left">
          <h2 className="text-2xl font-semibold text-principal">Antes de comenzar el juego</h2>
          <h1 className="text-xl text-softerRed">Quisiera hacerte unas preguntas</h1>
        </div>
        <div className="w-full mt-14">
          <FormJuegoDePalabras />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FormAndIntroJuegoDePalabras;
