export const cards = [
  {
    title: 'Elige',
    description:
      'Verás aparecer grupos de palabras en la pantalla.  Deberás elegir la o las palabras que identifican o  definen la cultura de tu organización.'
  },
  {
    title: '¿Cuántas palabras?',
    description:
      'En cada grupo tendrás la posibilidad de elegir hasta tres palabras que identifican o definen la cultura de tu organización.'
  },
  {
    title: 'Identifica',
    description:
      'No tienes que marcar palabras si no sientes que identifican o definen la cultura de tu organización. En la medida que avanza el juego, seguro encontrarás algunas.'
  },
  {
    title: 'Finaliza',
    description:
      'Al finalizar verás tu selección y podrás cambiar hasta dos palabras de las seleccionadas  y agregar alguna que no hayas encontrado en el juego.'
  }
];

export const containedByOrganizationOptions = [
  { text: 'Si', name: 'containedByOrganization', value: 'Si' },
  { text: 'No', name: 'containedByOrganization', value: 'No' },
  { text: 'A Veces', name: 'containedByOrganization', value: 'A Veces' }
];
export const proudOfWorkThereOptions = [
  { text: 'Siempre', name: 'proudOfWorkThere', value: 'Siempre' },
  { text: 'A veces', name: 'proudOfWorkThere', value: 'a veces' },
  { text: 'No', name: 'proudOfWorkThere', value: 'no' }
];
export const sameOrganizationPurposeOptions = [
  { text: 'Si', name: 'sameOrganizationPurpose', value: 'si' },
  { text: 'No', name: 'sameOrganizationPurpose', value: 'NO' },
  { text: 'No lo sé', name: 'sameOrganizationPurpose', value: 'No lo sé' }
];
export const societyImpactOptions = [
  { text: 'Si', name: 'societyImpact', value: 'SI' },
  { text: 'No', name: 'societyImpact', value: 'nO' },
  { text: 'Nunca se habló', name: 'societyImpact', value: 'Nunca se habló' },
  { text: 'No lo sé', name: 'societyImpact', value: 'no lo sé' }
];
