export const initialState = {
  birthDate: '',
  gender: '',
  identification: '',
  sexualOrientation: '',
  ethnicity: '',
  residenceCountry: 'Argentina',
  studyLevel: '',
  workField: '',
  jobArea: '',
  yearsInJob: '',
  yearsInCompany: '',
  peopleInCharge: '',
  howManyPeople: '',
  disability: '',
  disabilityType: ''
};
