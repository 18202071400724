import { createSlice } from '@reduxjs/toolkit';

export const juegoDePalabrasSlice = createSlice({
  name: 'juegoDePalabras',
  initialState: {
    step: 0,
    wordsReceivedFromApi: [],
    participantFeelingsAboutOrganizations: undefined,
    currentStepSelectedWords: [],
    allSelectedWords: [],
    currentStepRemainingWords: [],
    allRemainingWords: [],
    suggestedWord: '',
    error: undefined
  },
  reducers: {
    setParticipantFeelingsAboutOrganizations: (state, action) => {
      state.participantFeelingsAboutOrganizations = action.payload;
    },
    setWordsReceivedFromApi: (state, action) => {
      state.wordsReceivedFromApi = action.payload;
    },
    addStep: (state) => {
      state.step = state.step + 1;
    },
    setCurrentStepSelectedWords: (state, action) => {
      state.currentStepSelectedWords = action.payload;
    },
    setAllSelectedWords: (state, action) => {
      state.allSelectedWords = action.payload;
    },
    setCurrentStepRemainingWords: (state, action) => {
      state.currentStepRemainingWords = action.payload;
    },
    setAllRemainingWords: (state, action) => {
      state.allRemainingWords = action.payload;
    },
    setSuggestedWord: (state, action) => {
      state.suggestedWord = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const {
  setWordsReceivedFromApi,
  setParticipantFeelingsAboutOrganizations,
  addStep,
  setCurrentStepSelectedWords,
  setAllSelectedWords,
  setCurrentStepRemainingWords,
  setAllRemainingWords,
  setLastStepSelectedOption,
  setSuggestedWord,
  setError
} = juegoDePalabrasSlice.actions;

export default juegoDePalabrasSlice.reducer;
