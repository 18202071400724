import React from 'react';
import PropTypes from 'prop-types';
import Spinner from './Spinner';

import { getButtonClasses } from '../helpers/getClasses';

// eslint-disable-next-line react/prop-types
const Button = (props) => {
  return (
    <div className={getButtonClasses(props)}>
      <button
        type={props.type}
        onClick={!props.disabled ? props.onClick : null}
        disabled={props.disabled}
        className=" text-center w-full h-full font-bold px-5 py-5 ">
        {props.children}
      </button>
      {props.loading ? <Spinner /> : null}
    </div>
  );
};

export default Button;

Button.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.string,
  partial: PropTypes.bool,
  disabled: PropTypes.bool,
  customMargin: PropTypes.string,
  customWidth: PropTypes.string,
  loading: PropTypes.bool,
  up: PropTypes.bool,
  customPadding: PropTypes.string,
  customTextColor: PropTypes.string
};
