import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { setQuestions, setImages } from '../store/slices/juegoDeRespuestaAutomatica';
import { setWordsReceivedFromApi } from '../store/slices/juegoDePalabras';
import { setGameApiError, setLoading } from '../store/slices/userFlow';

import {
  FLOW_NOT_STARTED,
  FLOW_STARTED,
  FLOW_JRA_INTRO_VIEWED,
  FLOW_JRA_FORM_COMPLETED,
  FLOW_JRA_IMAGES_LOADED,
  FLOW_JRA_FINISHED,
  FLOW_JDP_LANDING_VIEWED,
  FLOW_JDP_FORM_COMPLETED,
  FLOW_JDP_FINISHED,
  FLOW_JDP_NO_WORDS_SELECTED,
  FLOW_JDP_NO_WORDS_SELECTED_WARNING_SEEN,
  FLOW_JDP_MODIFICATION_ANSWERS_STARTED,
  FLOW_JDP_CONFIRMED_ANSWERS,
  FLOW_FINISHED
} from '../consts/userFlowSteps';

import Question from './JuegoDeRespuestaAutomatica/Game/Question';
import OnlyData from './JuegoDeRespuestaAutomatica/Game/OnlyData';
import LoadingImages from './JuegoDeRespuestaAutomatica/Game/LoadingImages';
import Results from './Results/Results';
import GameDescription from './JuegoDeRespuestaAutomatica/GameDescription/GameDescription';
import JuegoDePalabrasGame from './JuegoDePalabras/Game/JuegoDePalabrasGame';
import AnswersReview from './JuegoDePalabras/Game/AnswersReview';
import LandingJuegoDePalabras from './JuegoDePalabras/Intro/LandingJuegoDePalabras';
import FormAndIntroJuegoDePalabras from './JuegoDePalabras/Form/FormAndIntroJuegoDePalabras';
import FormJuegoDeRespuestaAutomatica from './JuegoDeRespuestaAutomatica/Form/FormJuegoDeRespuestaAutomatica';
import ChangeAnswers from './JuegoDePalabras/Game/ChangeAnswers';
import JuegoDePalabrasLastStep from './JuegoDePalabras/Game/JuegoDePalabrasLastStep';
import NewUsersLanding from '../views/NewUsersLanding';
import { setUserFlowStep } from '../store/slices/userFlow';
import NoWordsSelectedWarning from './JuegoDePalabras/Game/NoWordsSelectedWarning';
import NoWordsSelectedChangeAnswers from './JuegoDePalabras/Game/NoWordsSelectedChangeAnswers';
import Loader from '../views/Loader';
import ErrorView from '../views/ErrorView';

const Game = () => {
  // Redux global state
  const dispatch = useDispatch();

  // Search params to skip games
  const [searchParams] = useSearchParams();
  const jumpJRA = searchParams.get('jumpJRA');
  const jumpJP = searchParams.get('jumpJP');

  const { userFlowStep, loading, gameApiError } = useSelector((state) => state.userFlow);

  const { questions, step } = useSelector((state) => state.juegoDeRespuestaAutomatica);

  // Prevent user from going back
  const [finishStatus, setfinishStatus] = useState(false);
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (
        window.confirm(
          'La opción de "ir atras" está deshabilitada. Se perderán todas las respuestas y deberá volver a comenzar el test. Si desea salir del test, cierre la pestaña.'
        )
      ) {
        setfinishStatus(true);
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  // Function to skip games if requested by query string and enabled in ENV var
  const lookForGameSkippingQueryStrings = () => {
    // eslint-disable-next-line no-undef
    if (process.env.REACT_APP_SKIP_GAMES === 'true') {
      if (jumpJRA) {
        dispatch(setUserFlowStep(FLOW_JRA_FINISHED));
      }
      if (jumpJP) {
        dispatch(setUserFlowStep(FLOW_FINISHED));
      }
    }
  };

  const fetchGameDataFromApi = () => {
    const images = [];
    dispatch(setLoading(true));
    // eslint-disable-next-line no-undef
    fetch(`${process.env.REACT_APP_API_URL}/status/${participantId}`)
      .then((res) => {
        dispatch(setLoading(false));
        return res.json();
      })
      .then((data) => {
        if (data.error || data.status != 200) {
          dispatch(setGameApiError(true));
          dispatch(setLoading(false));
        }
        if (data.status === 'jraAnswered') {
          dispatch(setUserFlowStep(FLOW_JRA_FINISHED));
        }
        if (data.status === 'gameAnswered') {
          dispatch(setUserFlowStep(FLOW_FINISHED));
        }

        if (data.jra) {
          dispatch(setQuestions(data.jra));

          for (let i = 0; i < data.jra.length; i++) {
            if (data.jra[i].image != undefined && images.includes(data.jra[i].image) == false) {
              images.push(data.jra[i].image);
            }
          }
          dispatch(setImages(images));
        }

        if (data.jp === 'answered') {
          dispatch(setUserFlowStep(FLOW_FINISHED));
        }

        if (data.jp) {
          dispatch(setWordsReceivedFromApi(data.jp));
        }

        if (!data.error) {
          dispatch(setGameApiError(false));
          dispatch(setLoading(false));
        }
      });
  };

  useEffect(() => {
    // Bring data from API. Or set error
    fetchGameDataFromApi();

    //Look for query string and skip games if requested
    lookForGameSkippingQueryStrings();

    // window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  // Get participantId from URL
  const { pathname } = useLocation();
  const participantId = pathname.split('/game/')[1];

  if ((gameApiError === null || gameApiError) && !loading) {
    return <ErrorView />;
  }

  if (loading) {
    return <Loader />;
  }

  if (userFlowStep === FLOW_NOT_STARTED) return <NewUsersLanding />;

  if (userFlowStep === FLOW_STARTED) {
    return <GameDescription />;
  }

  if (userFlowStep === FLOW_JRA_INTRO_VIEWED) {
    return <FormJuegoDeRespuestaAutomatica />;
  }

  if (userFlowStep === FLOW_JRA_FORM_COMPLETED) {
    return <LoadingImages />;
  }

  if (userFlowStep === FLOW_JRA_IMAGES_LOADED && questions[step]?.onlyData) {
    return <OnlyData />;
  }

  // Game
  if (userFlowStep === FLOW_JRA_IMAGES_LOADED) {
    return <Question />;
  }

  if (userFlowStep === FLOW_JRA_FINISHED) {
    return <LandingJuegoDePalabras />;
  }

  if (userFlowStep === FLOW_JDP_LANDING_VIEWED) {
    return <FormAndIntroJuegoDePalabras />;
  }

  if (userFlowStep === FLOW_JDP_FORM_COMPLETED) {
    return <JuegoDePalabrasGame />;
  }

  if (userFlowStep === FLOW_JDP_NO_WORDS_SELECTED) {
    return <NoWordsSelectedWarning />;
  }

  if (userFlowStep === FLOW_JDP_NO_WORDS_SELECTED_WARNING_SEEN) {
    return <NoWordsSelectedChangeAnswers />;
  }

  if (userFlowStep === FLOW_JDP_FINISHED) {
    return <AnswersReview />;
  }

  if (userFlowStep === FLOW_JDP_MODIFICATION_ANSWERS_STARTED) {
    return <ChangeAnswers />;
  }

  if (userFlowStep === FLOW_JDP_CONFIRMED_ANSWERS) {
    return <JuegoDePalabrasLastStep />;
  }

  if (userFlowStep === FLOW_FINISHED) {
    return <Results />;
  }
};

export default Game;
