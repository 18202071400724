import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Header, Button, Input, Spinner } from '../../../components';
import { setUserFlowStep } from '../../../store/slices/userFlow';
import { setSuggestedWord, setError } from '../../../store/slices/juegoDePalabras';
import { FLOW_FINISHED } from '../../../consts/userFlowSteps';
import SelectedWords from './SelectedWords';

const JuegoDePalabrasLastStep = () => {
  const dispatch = useDispatch();
  const [showMessage, setShowMessage] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { pathname } = useLocation();
  const participantId = pathname.split('/game/')[1];

  const { allSelectedWords, participantFeelingsAboutOrganizations, suggestedWord, error } =
    useSelector((state) => state.juegoDePalabras);

  const handleFinishButtonClick = async () => {
    try {
      // eslint-disable-next-line no-undef
      await axios.post(`${process.env.REACT_APP_API_URL}/jp/${participantId}`, {
        formAnswers: participantFeelingsAboutOrganizations,
        selectedWords: allSelectedWords
      });

      dispatch(setUserFlowStep(FLOW_FINISHED));
      setApiError(false);
      dispatch(setError(undefined));
    } catch (err) {
      setApiError(true);
      dispatch(setError(err.response.data.error));
    }
  };

  const suggestWord = (value) => {
    dispatch(setSuggestedWord(value));
  };

  if (showMessage && !apiError) {
    setTimeout(() => {
      setShowMessage(false);
    }, 3000);
  }

  const handleSendSuggestedWord = async () => {
    setLoading(true);
    try {
      // eslint-disable-next-line no-undef
      await axios.post(`${process.env.REACT_APP_API_URL}/jp/suggestNewWord/${participantId}`, {
        word: suggestedWord
      });
      setShowMessage(true);
      setApiError(false);
      dispatch(setError(undefined));
      dispatch(setSuggestedWord(''));
      setLoading(false);
    } catch (err) {
      setApiError(true);
      dispatch(setError(err.response.data.error));
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-between w-full px-10 mx-auto sm:w-5/6">
      <Header />

      <h1 className="text-center text-principal w-5/6 mx-auto text-lg">
        ¿Te gustaría sumar una palabra que no hayas visto en los grupos anteriores?{' '}
      </h1>

      <section className="pb-12 w-5/6 sm:w-3/6 mx-auto">
        {apiError ? (
          <p className="text-red-700 col-span-3 text-center">{error}</p>
        ) : showMessage ? (
          <p className="text-primary col-span-3 mt-4 text-center">Gracias por tu respuesta</p>
        ) : null}
        <div className="flex w-full gap-x-4 flex-col lg:flex-row">
          <div className="mt-5 sm:col-span-2 w-full">
            <div className="mb-5 md:mb-0 mt-5 flex items-center flex-wrap md:flex-nowrap">
              <Input
                type="text"
                noLabel
                handleChange={(e) => suggestWord(e.target.value)}
                name="suggestion"
                value={suggestedWord}
                placeholder="Escribe aquí tu nueva palabra"
                className="w-full"
                onKeyDown={(e) => {
                  if (suggestedWord.trim() !== '' && e.key === 'Enter') {
                    handleSendSuggestedWord();
                  }
                }}
              />

              <button
                className="w-full md:w-fit md:w-none md:ml-4 rounded-full h-10 md:h-16 px-6 py-2 bg-newSecondary text-white text-center"
                onClick={() => {
                  if (suggestedWord.trim() !== '' && !loading) {
                    handleSendSuggestedWord();
                  }
                }}>
                {loading ? <Spinner className="mr-auto" /> : 'Agregar'}
              </button>
            </div>

            <input name="option" id="no" type="radio" />
            <label htmlFor="no" className="pl-2 text-sm">
              {' '}
              No, gracias
            </label>
          </div>
        </div>
      </section>

      <div className="pb-3 md:py-5">
        <SelectedWords words={allSelectedWords} title="Tu selección final es:" />
      </div>

      <Button
        customWidth="w-5/6 md:w-96"
        partial
        customMargin="mx-auto my-10 md:my-24"
        onClick={handleFinishButtonClick}>
        Finalizar este juego y ver mis resultados
      </Button>
    </div>
  );
};

export default JuegoDePalabrasLastStep;
