import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
  const { pathname } = useLocation();

  return (
    <footer className="bg-zinc-900 w-full py-5 mt-12">
      <div className="w-full px-4 py-2 mx-auto sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="flex  py-5 justify-center  text-base  mx-auto items-center w-4/6 sm:w-auto  text-white ">
            <div className="text-left sm:text-center sm:divide-x-2 sm:mx-auto  w-full sm:items-center flex flex-col gap-6 sm:flex-row">
              {pathname !== '/politica-de-privacidad' && (
                <Link
                  className="hover:underline text-center hover:text-primary pl-4  "
                  to="/politica-de-privacidad"
                  target={
                    pathname === '/politica-de-privacidad' || pathname === '/sobre-iat'
                      ? '_self'
                      : '_blank'
                  }>
                  Política de Privacidad de Datos
                </Link>
              )}
              {pathname !== '/sobre-iat' && (
                <Link
                  className="hover:underline text-center hover:text-primary pl-4 "
                  to="/sobre-iat"
                  target={
                    pathname === '/sobre-iat' || pathname === '/politica-de-privacidad'
                      ? '_self'
                      : '_blank'
                  }>
                  Sobre IAT
                </Link>
              )}
              {pathname !== '/preguntas-frecuentes' && (
                <Link
                  className="hover:underline text-center hover:text-primary pl-4 "
                  to="/preguntas-frecuentes"
                  target={
                    pathname === '/preguntas-frecuentes' ||
                    pathname === '/sobre-iat' ||
                    pathname === '/politica-de-privacidad'
                      ? '_self'
                      : '_blank'
                  }>
                  Preguntas Frecuentes
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
