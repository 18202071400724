import React from 'react';
import { useSelector } from 'react-redux';
import { getJRAScalesTitles, getScaleText } from '../../helpers';
import ScaleChart from './ScaleChart';

const Scales = () => {
  const { resultsData } = useSelector((state) => state.gameResults);

  return (
    <div className="gap-3 mt-6 flex flex-col">
      {resultsData?.jra?.map((value, index) => {
        const scaleText = getScaleText(value, index);
        return (
          <div
            key={index}
            className={`flex flex-col lg:flex-row gap-5 items-center ${
              index === 0 && 'items-center my-auto'
            }`}>
            <ScaleChart
              value={value === Math.floor(value) ? Number(value) : Number(value.toFixed(2))}
              title={getJRAScalesTitles(index)}
              subtitle={
                index === 2
                  ? 'Cuando el juego presenta todas la categorías de medición lo llamamos zona de Aprendizaje. Por ejemplo si la tendencia anterior es el amarillo, ahora tus respuestas deberían estar más cerca del verde. ¿Cómo te ha ido?'
                  : null
              }
              showLabelColor
              titleClassName="text-slate-400"
            />

            <p className={`sm:w-5/6 text-left ${scaleText.length > 1000 ? ' md:mt-28' : ''}`}>
              {scaleText}
              <br />
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default Scales;
