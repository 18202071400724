import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addStep } from '../../../store/slices/juegoDeRespuestaAutomatica';
import { Button } from '../../../components';

/* eslint-disable react/prop-types */
const OnlyData = () => {
  const dispatch = useDispatch();
  const { questions, step } = useSelector((state) => state.juegoDeRespuestaAutomatica);

  // Scroll up to top on page load (mainly for mobile)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!questions[step]) {
    return null;
  }

  const title = questions[step].title
    .split(' ')
    .map((part) => `${part} `)
    .map((part, i) =>
      i === 0 ? (
        <strong className="text-red-600" key={part}>
          {part}
        </strong>
      ) : (
        part
      )
    );

  return (
    <>
      <div className="w-screen flex flex-col xl:w-full xl:mx-auto  items-center ">
        <div className="w-full text-left h-screen sm:py-24 justify-start sm:justify-center flex flex-col xl:px-48">
          <div className="mx-8  ">
            <p className="text-xl sm:text-3xl block text-principal font-semibold border-b border-black text-left py-4 sm:py-8 my-8">
              Estás en la etapa {title}
            </p>
            <p
              className="my-10 sm:my-24 sm:w-full sm:text-xl text-principal"
              dangerouslySetInnerHTML={{ __html: questions[step].description }}></p>
          </div>
          <Button onClick={() => dispatch(addStep())}>{questions[step]?.button}</Button>
        </div>
      </div>
    </>
  );
};

export default OnlyData;
