import { createSlice } from '@reduxjs/toolkit';

export const gameResultsSlice = createSlice({
  name: 'gameResults',
  initialState: {
    resultsData: undefined
  },
  reducers: {
    setResultsData: (state, action) => {
      state.resultsData = action.payload;
    }
  }
});

export const { setResultsData } = gameResultsSlice.actions;

export default gameResultsSlice.reducer;
