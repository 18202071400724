import errorIcon from '../assets/icons/error.svg';
import successIcon from '../assets/icons/success.svg';
import infoIcon from '../assets/icons/info.svg';
import { NotificationType } from '../components/Notification';

export const getButtonClasses = (props) => {
  const {
    variant,
    partial,
    disabled,
    customMargin,
    customWidth,
    up,
    customPadding,
    customTextColor
  } = props;
  const classes = [];

  if (customMargin) {
    classes.push(customMargin);
  } else if (partial) {
    classes.push('mt-0');
  } else {
    classes.push('mt-8');
  }
  if (customPadding) {
    classes.push(customPadding);
  }
  if (variant === 'secondary') {
    classes.push('bg-secondary');
  } else if (variant === 'link') {
    classes.push('bg-transparent');
  } else if (disabled) {
    classes.push('bg-gray-300 cursor-not-allowed');
  } else {
    classes.push('bg-newSecondary');
  }
  if (customWidth) {
    classes.push(customWidth);
  } else {
    classes.push('w-56');
  }

  if (customTextColor) {
    classes.push(customTextColor);
  } else {
    classes.push('text-white');
  }
  if (partial) {
    classes.push('py-6');
  } else {
    classes.push('mx-auto py-3');
  }

  if (up) {
    classes.push('mb-24 sm:mb-4');
  } else if (!customMargin) {
    classes.push('mb-4');
  }
  classes.push(`text-base font-regular flex text-center items-center  rounded-md`);
  return classes.join(' ');
};

export const getNotificationClasses = (type) => {
  switch (type) {
    case NotificationType.ERROR:
      return 'bg-red-50 border-red-400 text-red-600';
    case NotificationType.SUCCESS:
      return 'bg-green-50 border-green-400 text-green-600';
    case 'info':
      return 'bg-amber-50 border-notification text-notification';
  }
};

export const getNotificationIcons = (type) => {
  switch (type) {
    case NotificationType.ERROR:
      return errorIcon;
    case NotificationType.SUCCESS:
      return successIcon;
    case 'info':
      return infoIcon;
  }
};

export const getGraphBorder = (value) => {
  if ((value >= -3 && value < -2) || (value > 2 && value <= 3)) return 'border-[#FF0000]';
  if ((value >= -2 && value < -1) || (value > 1 && value <= 2)) return 'border-[#FFA500]';
  if (value >= -1 && value < 1) return 'border-[#FFD700]';

  // permeability
  if (value >= -300 && value < -200) return 'border-red-800';
  if (value >= -200 && value < -100) return 'border-orange-500';
  if (value >= -100 && value < 100) return 'border-red-400';
  if (value >= 100 && value < 200) return 'border-yellow-500';
  if (value >= 300) return 'border-teal-600';

  return 'border-[#32CD32]';
};
