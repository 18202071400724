/* eslint-disable react/prop-types */
import React from 'react';

const Select = ({
  title,
  value,
  handleChange,
  name,
  options,
  className,
  placeholder,
  errors,
  required = false
}) => {
  return (
    <div className="flex flex-col my-5">
      <label htmlFor={value} className="block  font-regular my-3 text-black text-md text-left">
        {title}
        {required && <span className="text-red-600"> *</span>}
      </label>
      <select
        value={value}
        onChange={handleChange}
        id={value}
        className={`mt-1 block w-full pl-3 pr-10 py-3 text-base border-gray-100 rounded-full shadow-sm cursor-pointer  focus:outline-none focus:ring-primary focus:border-primary sm:text-sm  ${className}`}
        name={name}>
        <option value={placeholder}>{placeholder}</option>
        {options.map((option) => (
          <option key={option.id || option.code || option.value} value={option.value}>
            {option.text || option.value}
          </option>
        ))}
      </select>
      {errors && <p className="text-red-700 text-sm">{errors}</p>}
    </div>
  );
};

export default Select;
