import React, { Fragment, useEffect, useMemo } from 'react';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';

import { Header } from '../../components';
import ScaleChart from './ScaleChart';
import { cards, statsQustions, tooltips } from '../../consts/stats';
import RadarChart from './RadarChart';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setError } from '../../store/slices/juegoDePalabras';
import { setGameApiError } from '../../store/slices/userFlow';
import Scales from './Scales';
import { setResultsData } from '../../store/slices/gameResults';
import { getFixedNumber, getResultColor, isColorHex } from '../../helpers';
import { chain, keys } from 'lodash';

import { getScaleText } from '../../helpers';

const Results = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const gameId = pathname.split('/')[2];

  const { resultsData } = useSelector((state) => state.gameResults);

  const getResultsData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/stats/${gameId}`);
      dispatch(setResultsData(res.data));
    } catch (error) {
      dispatch(setGameApiError(true));
      dispatch(setError(error));
    }
  };

  useEffect(() => {
    getResultsData();
  }, []);

  const globalResultColor = getResultColor(resultsData?.globalAverage || 0);
  const globalResultColorStyle = {};
  if (isColorHex(globalResultColor)) {
    globalResultColorStyle.backgroundColor = globalResultColor;
  }

  const selectedWordsByArch = resultsData?.jp?.selectedWords ?? {};
  const selectedWords = useMemo(
    () => chain(selectedWordsByArch).values().flatten().uniq().value(),
    [selectedWordsByArch]
  );

  return (
    <div className="w-full min-h-screen flex flex-col justify-between items-center text-slate-700">
      <Header isFull={true} />

      <div className="w-full px-10 md:px-0 sm:w-5/6 mx-auto mt-14">
        <p className="text-red-600 font-semibold text-xl">
          ¡Lo lograste! ¡Has completado todos los Juegos!
        </p>

        <div className="mt-14 grid grid-cols-1 lg:grid-cols-3 gap-x-5 gap-y-3 lg:gap-y-0">
          {cards.map((card, index) => (
            <div
              className="py-5 px-8 bg-gray-100 border-gray-500 text-slate-700 rounded-md border-2"
              key={index}>
              <h2 className="text-xl font-semibold p-3">{card.title}</h2>
              <p className="p-3">{card.text}</p>
            </div>
          ))}
        </div>
        <div className="my-5 py-5 px-8 bg-gray-100 border-gray-500 rounded-md border-2">
          <p className="p-3">
            El <strong>Juego de Respuesta Automática</strong> es una herramienta más de{' '}
            <strong>autoconocimiento</strong>. Nos ayuda a llevar a la consciencia nuestras
            respuestas automáticas, llamadas y conocidas como <strong>Sesgos Implícitos</strong>,
            para entender cómo actúa nuestro cerebro en función de las creencias profundas
            adquieridas a lo largo de nuestra vida.{' '}
            <strong>NO HAY RESULTADOS BUENOS NI MALOS</strong>, los resultados son sólo un dato más,
            no son una verdad absoluta. Sí es importante comprender que los sesgos nos ayudan a
            optimizar decisiones, ya que sería imposible contar con un abanico de opciones, quizá,
            infinitas. <strong>El problema del sesgo</strong> se presenta cuando dentro del abanico
            de opciones ante una determinada decisión, se invalida cualquier otra opción o ni
            siquiera aparecen otras opciones posibles. Por ejemplo si para un C-Level nuestro sesgo
            se inclina siempre hacia una persona de sexo biólogico masculino, se invalida a
            cualquier otra persona que no entre dentro de esa categoría. Esto podría tener como
            consecuencia que de forma inconsciente no reconozcamos, por ejemplo, a una mujer líder
            como tal sino cumple con determinadas condiciones que nuestro sistema de creencias dá
            como válidas.
          </p>
        </div>
        <br />
        <br />
        <p className="font-bold">¿Cómo interpretar tu resultado?</p>
        <br />
        <p className="font-medium">
          Efecto Respuesta Automática (RA) Representa el resultado general del juego en un rango de
          datos de -3 y +3, mostrando una tendencia. Los resultados de cada categoría del juego
          también serán representadas con el mismo rango de datos.
        </p>

        <div className="flex w-full md:my-5 relative md:max-w-4xl md:mx-auto justify-between">
          <ScaleChart hideValue hideNeedle />
        </div>
        <div className="flex w-full justify-between">
          <p className="text-left text-xs sm:text-base md:text-lg md:text-left">
            C-Level-Masculino-Hard
            <br />
            Colaborador/a-Femenino-Soft
          </p>
          <p className="text-right text-xs sm:text-base md:text-lg">
            C-Level-Femenino-Hard
            <br />
            Colaborador/a-Masculino-Soft
          </p>
        </div>

        <br />

        <section className="mt-14">
          <p className="font-bold">Este es tu efecto Respuesta Automática</p>

          <p className="flex flex-col md:flex-row justify-center items-center my-12 p-x-10 py-5 rounded-md font-semibold bg-gray-100 border-gray-500 border-1 text-center">
            <p className="text-center mb-5 md:text-right md:mr-4 md:mb-0">
              {getScaleText(resultsData?.globalAverage, -1)}
            </p>
            <div
              style={globalResultColorStyle}
              className={cx([
                'w-28 h-14 uppercase rounded-md font-semibold text-2xl text-center text-white flex justify-center items-center',
                {
                  [globalResultColor]: !isColorHex(globalResultColor)
                }
              ])}>
              <span>{getFixedNumber(resultsData?.globalAverage || 0)}</span>
            </div>
          </p>

          <Scales />
        </section>

        <section className="mt-14">
          <p className="text-lg font-bold">Juego de Palabras</p>
          <br />
          <p>
            El Juego de Palabras fue diseñado para definir la cultura organizacional de acuerdo a
            cómo la perciben y definen las personas que trabajan en una organización.
          </p>
          <div>
            Este juego está basado en cinco arquetipos culturales:{' '}
            {tooltips.map((tooltip, index) => (
              <Fragment key={index}>
                <span data-tip data-for={tooltip.title} className="text-slate-400 cursor-pointer">
                  {tooltip.title}
                  {index !== tooltips.length - 2
                    ? index !== tooltips.length - 1
                      ? ', '
                      : '.'
                    : ' y '}
                </span>
                <ReactTooltip
                  id={tooltip.title}
                  effect="solid"
                  backgroundColor="#94A3B8"
                  place="top">
                  <div className="w-auto mx-auto max-w-md text-center p-0 bg-slate-400 text-white">
                    {tooltip.text}
                  </div>
                </ReactTooltip>
              </Fragment>
            ))}
          </div>

          <div className="mt-14 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
            {selectedWords.map((word) => (
              <p
                className="uppercase border text-center border-secondary font-bold bg-gray-50 rounded-md p-3 sm:p-4 whitespace-nowrap text-xs md:text-lg text-secondary"
                key={word}>
                {word}
              </p>
            ))}
          </div>

          <div className="relative mt-24 text-base md:text-xl">
            <div className="w-1 bg-primary absolute top-0 bottom-0 m-auto left-0 right-0"></div>
            {keys(selectedWordsByArch).map((arch) => (
              <div className="flex  gap-x-3 md:gap-x-24 mt-10" key={arch}>
                <div className="flex-1 text-right">
                  <p className="text-red-500 uppercase">{arch}</p>
                </div>
                <div className="flex-1 text-left">
                  {selectedWordsByArch[arch].map((word) => (
                    <p key={word} className="uppercase font-medium">
                      {word}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="mt-14">
          <p className="my-12 p-x-10 py-5 rounded-md font-semibold bg-gray-100 border-gray-500 border-1 text-center">
            Lo que verás a continuación es cómo percibes y defines tú la cultura de tu organización.
          </p>
        </section>

        <section className="w-full">
          <RadarChart data={resultsData?.jp} />
        </section>

        <section className="my-14 grid gap-5">
          <div>
            <p className="text-slate-400 text-xl font-bold">Para reflexionar</p>
            <div className="border-2 px-16 py-4 rounded-lg mt-5 bg-gray-100 border-zinc-400 border-1">
              <ul className="list-decimal">
                {statsQustions.map((question, index) => (
                  <li key={index} className="text-black font-medium my-4">
                    {question}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/* <div>
            <p className="text-slate-400 text-xl font-bold">Lecturas recomendadas</p>
            <div className="border-2 px-16 py-4 rounded-lg mt-5 bg-gray-100 border-zinc-400 border-1">
              <ul>
                {recommendedReads.map((link, index) => (
                  <li key={index} className="text-black font-medium my-4">
                    <a
                      className="text-black hover:underline"
                      href="https://www.humaninhaus.com/"
                      target="_blank"
                      rel="noreferrer">
                      {link}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div> */}
        </section>
      </div>
    </div>
  );
};

export default Results;
