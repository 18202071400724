import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Button, Checkbox } from '../../../components';
import { setUserFlowStep } from '../../../store/slices/userFlow';
import { FLOW_JDP_CONFIRMED_ANSWERS } from '../../../consts/userFlowSteps';
import { setAllSelectedWords } from '../../../store/slices/juegoDePalabras';

const NoWordsSelectedChangeAnswers = () => {
  const dispatch = useDispatch();
  const { allSelectedWords, wordsReceivedFromApi } = useSelector((state) => state.juegoDePalabras);
  const [availableChanges, setAvailableChanges] = useState(2 - allSelectedWords.length);

  const handleSelectChange = (word) => {
    setAvailableChanges(availableChanges - 1);
    dispatch(setAllSelectedWords([...allSelectedWords, word].flat()));

    // if we uncheck a word, we need to add it back to the available changes
    if (allSelectedWords.includes(word)) {
      setAvailableChanges(availableChanges + 1);
      dispatch(setAllSelectedWords(allSelectedWords.filter((w) => w !== word)));
    }
  };

  return (
    <div className="h-screen flex flex-col gap-y-6">
      <div>
        <Header />

        {availableChanges > 0 ? (
          <h1 className="text-center text-principal text-xl text-primary mb-12 w-5/6 sm:w-4/6 mx-auto">
            {allSelectedWords.length === 0
              ? `Selecciona ${availableChanges} palabras`
              : `Selecciona ${
                  allSelectedWords.length === 1 ? allSelectedWords.length : availableChanges
                } palabras más`}
          </h1>
        ) : null}
      </div>
      {wordsReceivedFromApi.flat().length > 0 && (
        <main className="grid grid-cols-2 md:grid-cols-3 mx-auto gap-4">
          {wordsReceivedFromApi.flat().map((word) => (
            <Checkbox
              name="word"
              game="jdp"
              value={word}
              text={word}
              className="text-center"
              checked={allSelectedWords.includes(word)}
              handleChange={() => handleSelectChange(word)}
              disabled={allSelectedWords.length >= 2 && !allSelectedWords.includes(word)}
              key={word}
            />
          ))}
        </main>
      )}
      <div className="  flex flex-col justify-center">
        {allSelectedWords.length < 2 && (
          <p className="text-red-700 text-center">
            Selecciona {availableChanges} {availableChanges === 1 ? 'palabra' : 'palabras'}
          </p>
        )}
        <Button
          disabled={allSelectedWords.length < 2}
          onClick={() => dispatch(setUserFlowStep(FLOW_JDP_CONFIRMED_ANSWERS))}>
          Seleccionar palabras ahora
        </Button>
      </div>
    </div>
  );
};

export default NoWordsSelectedChangeAnswers;
