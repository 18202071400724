import Router from './routes';
import LogRocket from 'logrocket';
import './App.css';

function App() {
  LogRocket.init('jplqif/humaninlab');
  return (
    <>
      <Router />
    </>
  );
}

export default App;
