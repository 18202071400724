import { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { addStep, addAnswer } from '../../../store/slices/juegoDeRespuestaAutomatica';
import { setUserFlowStep } from '../../../store/slices/userFlow';
import { FLOW_JRA_FINISHED } from '../../../consts/userFlowSteps';
import { Header, Footer } from '../../../components';

import createTimer from 'unitimer';

/* eslint-disable react/prop-types */
const Question = () => {
  const dispatch = useDispatch();
  // Get participantId from URL
  const { pathname } = useLocation();
  const participantId = pathname.split('/game/')[1];

  const [apiStatus, setApiStatus] = useState();
  const { questions, step, answers, participant } = useSelector(
    (state) => state.juegoDeRespuestaAutomatica
  );

  const [showIncorrectAnswer, setShowIncorrectAnswer] = useState(false);

  const selectionCounter = useRef(0);
  const selectedTime = useRef(0);
  const answerSelected = useRef(0);

  // Function to start the timer
  let timer;
  timer = createTimer().start();

  const handleOptionClick = (answer) => {
    selectionCounter.current += 1;

    if (selectionCounter.current === 1) {
      timer.stop();
      selectedTime.current = timer.mean();
      answerSelected.current = answer;
    }

    if (answer != questions[step].correct) {
      setShowIncorrectAnswer(true);
      setTimeout(() => {
        setShowIncorrectAnswer(false);
      }, 500);
      return;
    }

    // It it's the last step, send the answers to the API and advance in user flow
    if (questions.length - 1 === step) {
      try {
        // eslint-disable-next-line no-undef
        axios.post(`${process.env.REACT_APP_API_URL}/jra/${participantId}`, {
          answers,
          participant
        });
        setApiStatus(200);
        dispatch(setUserFlowStep(FLOW_JRA_FINISHED));
      } catch (error) {
        setApiStatus(500);
      }
    } else {
      dispatch(
        addAnswer({
          currentStepName: questions[step].name,
          selectedAnswer: answerSelected.current,
          correct: questions[step].correct === answerSelected.current,
          time: selectedTime.current
        })
      );

      dispatch(addStep());
      selectionCounter.current = 0;
      selectedTime.current = 0;
    }
  };

  const errorMessage = (
    <div className="w-screen h-screen flex flex-col justify-between">
      <Header />
      <div className="w-full text-center text-2xl">
        <p>Hubo un error al enviar el test. Contacta al administrador.</p>
      </div>
      <Footer />
    </div>
  );

  if (apiStatus === 500) {
    return errorMessage;
  }

  return (
    <div className="h-screen flex flex-col justify-between">
      <div className=" flex mt-14 sm:mt-0 sm:h-full justify-center items-center">
        <img
          className=" rounded-lg w-5/6 max-w-2xl sm:block sm:mx-auto"
          src={questions[step]?.image}
        />
      </div>
      <div className="flex h-[400px] sm:h-72 justify-center bg-back md:gap-20 lg:gap-40">
        <div className="m-auto md:mx-0 flex justify-center flex-col">
          {showIncorrectAnswer && answerSelected.current === 'a' ? (
            <p className="text-center text-2xl w-full mb-4 text-red-700 relative ">
              ¡Respuesta Incorrecta!
            </p>
          ) : (
            <p className="opacity-0 text-2xl mb-4 text-softerRed">¡Respuesta Incorrecta!</p>
          )}
          <button
            className={cx([
              `border-2 p-2.5 min-h-[70px] py-10 sm:py-0 bg-buttons mb-8 rounded-xl mx-auto w-44 md:w-96`,
              {
                'border-red-600':
                  showIncorrectAnswer &&
                  questions[step]?.correct === 'b' &&
                  answerSelected.current === 'a'
              }
            ])}
            onClick={() => handleOptionClick('a')}
            disabled={showIncorrectAnswer}>
            {questions[step].textA.map((option, index) => (
              <p key={index} className=" font-regular text-xl" style={{ color: option.color }}>
                {option.text}
              </p>
            ))}
          </button>
        </div>

        <div className="m-auto md:mx-0 flex justify-center flex-col">
          {showIncorrectAnswer && answerSelected.current === 'b' ? (
            <p className="text-center text-2xl w-full mb-4 text-red-700 relative ">
              ¡Respuesta Incorrecta!
            </p>
          ) : (
            <p className="opacity-0 text-2xl mb-4">Respuesta Incorrecta</p>
          )}
          <button
            className={cx([
              `border-2 p-2.5 min-h-[70px] py-10 sm:py-0 bg-buttons mb-8 rounded-xl mx-auto w-44 md:w-96`,
              {
                'border-red-600':
                  showIncorrectAnswer &&
                  questions[step]?.correct === 'a' &&
                  answerSelected.current === 'b'
              }
            ])}
            onClick={() => handleOptionClick('b')}
            disabled={showIncorrectAnswer}>
            {questions[step].textB.map((option, index) => (
              <p key={index} className=" font-regular text-xl" style={{ color: option.color }}>
                {option.text}
              </p>
            ))}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Question;
