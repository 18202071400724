import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Checkbox = ({
  name,
  value,
  handleChange,
  text,
  checked,
  disabled,
  className,
  game,
  customWidth
}) => {
  return (
    <div className={`grid grid-cols-1 mb-1 ${customWidth ? customWidth : 'w-full'}`}>
      <div className="relative">
        <input
          className={`hidden group peer ${className}`}
          type="checkbox"
          name={name}
          value={value}
          onChange={handleChange}
          checked={checked}
          id={value}
          disabled={disabled}
        />

        <label
          className={cx([
            'block',
            {
              'text-center border-newOption p-3 rounded  peer-checked:shadow-lg  peer-checked:ring-4 bg-slate-50':
                game === 'jdp',
              'border-gray-100 rounded-lg peer-checked: ring-1 p-4': game !== 'jdp',
              'opacity-50 cursor-default': disabled,
              'opacity-100 cursor-pointer': !disabled
            },
            'text-sm font-medium transition-colors border shadow-sm hover:bg-gray-50  peer-checked:ring-newOption'
          ])}
          htmlFor={value}>
          <span
            className={
              game === 'jdp'
                ? 'text-center font-normal uppercase text-md md:text-xl text-secondary'
                : null
            }>
            {text}
          </span>
        </label>

        {game && game !== 'jdp' && game !== 'jdp/edit' ? (
          <svg
            className="absolute w-5 h-5 text-primary opacity-0 top-4 right-4 peer-checked:opacity-100"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor">
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        ) : null}
      </div>
    </div>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  text: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  game: PropTypes.string,
  customWidth: PropTypes.string
};
