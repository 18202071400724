/* eslint-disable no-unused-vars*/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Button, Checkbox, Notification } from '../../../components';
import { setUserFlowStep } from '../../../store/slices/userFlow';
import { setAllSelectedWords } from '../../../store/slices/juegoDePalabras';
import { FLOW_JDP_CONFIRMED_ANSWERS } from '../../../consts/userFlowSteps';

const ChangeAnswers = () => {
  const dispatch = useDispatch();
  const [availableAddingChanges, setAvailableAddingChanges] = useState(2);
  const [availableRemovingChanges, setAvailableRemovingChanges] = useState(2);
  const [orderedWords, setOrderedWords] = useState([]);

  const { allSelectedWords, wordsReceivedFromApi } = useSelector((state) => state.juegoDePalabras);

  const handleSelectChange = (word) => {
    if (!allSelectedWords.includes(word) && availableAddingChanges > 0) {
      setAvailableAddingChanges(availableAddingChanges - 1);
      dispatch(setAllSelectedWords([...allSelectedWords, word]));
    }
    if (allSelectedWords.includes(word) && availableRemovingChanges > 0) {
      setAvailableRemovingChanges(availableRemovingChanges - 1);
      dispatch(
        setAllSelectedWords(allSelectedWords.filter((selectedWord) => selectedWord !== word))
      );
    }

    if (
      allSelectedWords.includes(word) &&
      availableRemovingChanges === 0 &&
      availableAddingChanges < 2
    ) {
      setAvailableAddingChanges(availableAddingChanges + 1);
      dispatch(
        setAllSelectedWords(allSelectedWords.filter((selectedWord) => selectedWord !== word))
      );
    }
  };

  const words = wordsReceivedFromApi?.flat().sort((a, b) => {
    if (allSelectedWords.includes(a) && !allSelectedWords.includes(b)) {
      return -1;
    }
    if (!allSelectedWords.includes(a) && allSelectedWords.includes(b)) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    if (orderedWords.length === 0) {
      setOrderedWords(words);
    }
  }, [orderedWords]);

  return (
    <div className="h-screen flex flex-col justify-between">
      <div>
        <Header />
        <h1 className="text-center text-md sm:text-lg text-secondary w-5/6 mt-16 mx-auto">
          Para cambiar hasta 2 palabras de tu selección, cliquea sobre la o las palabras que quieras
          cambiar marcadas en color azul y éstas se desactivarán. Para agregar una nueva palabra haz
          clic sobre las que se visualizan en color gris para activarlas.
        </h1>
        <div className="my-10 sm:my-12 w-5/6 mx-auto text-center">
          {availableAddingChanges > 0 || availableRemovingChanges > 0 ? (
            <>
              <p className="font-semiBold">
                Puedes agregar <span className="text-softerRed">{availableAddingChanges}</span>{' '}
                palabras
              </p>
              <p className="font-semiBold mt-3">
                Puedes quitar <span className="text-softerRed">{availableRemovingChanges}</span>{' '}
                palabras
              </p>
            </>
          ) : (
            <Notification type="info" message="No tienes más cambios disponibles" />
          )}
        </div>
      </div>
      <div className="grid md:w-5/6 gap-x-3 xl:gap-4 mx-auto grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {orderedWords.map((word) => (
          <Checkbox
            name="word"
            game="jdp"
            value={word}
            text={word}
            checked={allSelectedWords?.includes(word)}
            customWidth=" whitespace-nowrap w-full"
            className="text-center"
            handleChange={() => handleSelectChange(word)}
            disabled={availableAddingChanges === 0 && !allSelectedWords?.includes(word)}
            key={word}
          />
        ))}
      </div>
      <div>
        <Button onClick={() => dispatch(setUserFlowStep(FLOW_JDP_CONFIRMED_ANSWERS))}>
          Cambiar las palabras y continuar
        </Button>
      </div>
    </div>
  );
};

export default ChangeAnswers;
