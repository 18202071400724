import { useState, useEffect } from 'react';
import { Spinner } from '../../../components';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setUserFlowStep } from '../../../store/slices/userFlow';
import { FLOW_JRA_IMAGES_LOADED } from '../../../consts/userFlowSteps';

const LoadingImages = () => {
  const dispatch = useDispatch();
  const { images } = useSelector((state) => state.juegoDeRespuestaAutomatica);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const loaded = setTimeout(() => {
      setIsLoaded(true);
    }, 5000);
    return () => clearTimeout(loaded);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      dispatch(setUserFlowStep(FLOW_JRA_IMAGES_LOADED));
    }
  }, [isLoaded]);

  return (
    <div className="w-screen h-screen grid place-items-center">
      <div className="flex">
        {images.map((e, index) => (
          <img src={e} key={index} style={{ width: '1px' }} />
        ))}
      </div>
      <Spinner />
      <p>Estamos cargando el juego...</p>
    </div>
  );
};

export default LoadingImages;
