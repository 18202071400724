import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { setParticipantData } from '../../../store/slices/juegoDeRespuestaAutomatica';
import { setUserFlowStep } from '../../../store/slices/userFlow';
import { FLOW_JRA_FORM_COMPLETED } from '../../../consts/userFlowSteps';
import { Radio, Select, Header, Footer, Button } from '../../../components';
import {
  genderOptions,
  identificationOptions,
  // sexualOrientationOptions,
  ethnicityOptions,
  countryOptions,
  studyLevelOptions,
  jobAreaOptions,
  yearsInJobOptions,
  yesNoOptions,
  howManyPeopleOptions,
  disabilityOptions,
  yesNoOptionsDisability,
  jobTitleOptions,
  birthYearOptions
} from '../../../consts/juegoDeRespuestaAutomatica/form/formOptions';
import { initialState } from '../../../consts/juegoDeRespuestaAutomatica/form/formInitialState';
import { validate } from '../../../helpers';

const FormJuegoDeRespuestaAutomatica = () => {
  const dispatch = useDispatch();

  // Scroll up to top on page load (mainly for mobile)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="w-full flex flex-col items-center">
        <Header />

        <div className="w-5/6 sm:w-3/6 mx-auto">
          <p className="block  font-regular my-3 text-black text-md text-left">
            Antes de ir al juego, necesitamos unos datos, que nos serán de utilidad para la
            presentación de resultados. Recordá que sólo los ítems marcados con * son los
            obligatorios.
          </p>

          <Formik
            initialValues={initialState}
            validateOnBlur={false}
            validateOnChange={false}
            validate={validate}
            onSubmit={(values) => {
              dispatch(setParticipantData(values));
              dispatch(setUserFlowStep(FLOW_JRA_FORM_COMPLETED));
            }}>
            {({ errors, values, handleSubmit, handleChange }) => (
              <form onSubmit={handleSubmit} className="mt-8 w">
                <Select
                  options={jobTitleOptions}
                  title="¿Nombre del Cargo o Puesto que ocupas?"
                  handleChange={handleChange}
                  name="workField"
                  value={values.workField}
                  placeholder="Selecciona"
                  className={errors.workField ? 'border-red-700' : ''}
                  errors={errors.workField}
                  required
                />
                <Select
                  options={jobAreaOptions}
                  title={'Área de la empresa en la que trabajas'}
                  handleChange={handleChange}
                  name="jobArea"
                  value={values.jobArea}
                  className={errors.jobArea ? 'border-red-700' : ''}
                  placeholder="Selecciona"
                  errors={errors.jobArea}
                  required
                />
                <Select
                  options={yearsInJobOptions}
                  title="¿Qué antiguedad tienes en tu Cargo Actual?"
                  handleChange={handleChange}
                  name="yearsInJob"
                  value={values.yearsInJob}
                  placeholder="Selecciona"
                  errors={errors.yearsInJob}
                  required
                />
                <Select
                  options={yearsInJobOptions}
                  title="¿Y en la compañía u organización?"
                  handleChange={handleChange}
                  name="yearsInCompany"
                  value={values.yearsInCompany}
                  placeholder="Selecciona"
                  errors={errors.yearsInCompany}
                  required
                />
                <div className="w-full my-8 grid grid-cols-1 sm:grid-cols-2 sm:gap-5">
                  <div>
                    <h1 className="block font-regular my-4 text-black text-md text-left">
                      ¿Tienes personas a tu cargo?<span className="text-red-600"> *</span>
                    </h1>
                    {yesNoOptions.map((option) => (
                      <Radio
                        key={option.value}
                        value={option.value}
                        text={option.value}
                        name={option.name}
                        handleChange={handleChange}
                      />
                    ))}
                    {errors.peopleInCharge && (
                      <p className="text-red-700 my-3 text-left">{errors.peopleInCharge}</p>
                    )}
                  </div>

                  <div>
                    <h1 className="block font-regular my-4 text-black text-md text-left">
                      ¿Cuántas personas?
                    </h1>
                    {howManyPeopleOptions.map((option) => (
                      <Radio
                        key={option.value}
                        value={option.value}
                        text={option.value}
                        name={option.name}
                        handleChange={handleChange}
                        disabled={values.peopleInCharge === '' || values.peopleInCharge === 'No'}
                      />
                    ))}
                    {errors.howManyPeople && (
                      <p className="text-red-700 my-3 text-left">{errors.howManyPeople}</p>
                    )}
                  </div>
                </div>

                <Select
                  options={studyLevelOptions}
                  title="Nivel de estudios"
                  handleChange={handleChange}
                  name="studyLevel"
                  value={values.studyLevel}
                  placeholder="Selecciona"
                  className={errors.studyLevel ? 'border-red-700' : ''}
                  errors={errors.studyLevel}
                  required
                />

                <div className="my-8">
                  <h1 className="block  font-regular my-4  text-black text-md text-left">
                    Sexo biológico que figura en tu acta de nacimiento
                    <span className="text-red-600"> *</span>
                  </h1>
                  {genderOptions.map((option) => (
                    <Radio
                      key={option.id}
                      value={option.value}
                      text={option.text}
                      name={option.name}
                      handleChange={handleChange}
                      className={errors.gender ? 'border-red-700' : ''}
                    />
                  ))}
                  {errors.gender && <p className="text-red-700 my-3 text-left">{errors.gender}</p>}
                </div>
                <div className="my-8">
                  <h1 className="block  font-regular my-4 text-black text-md text-left">
                    ¿Con qué género te identificas?
                  </h1>
                  {identificationOptions.map((option) => (
                    <Radio
                      key={option.id}
                      value={option.value}
                      text={option.text}
                      name={option.name}
                      handleChange={handleChange}
                    />
                  ))}
                </div>

                <Select
                  options={countryOptions}
                  title="¿Cuál es tu país de residencia?"
                  handleChange={handleChange}
                  name="residenceCountry"
                  value={values.residenceCountry}
                  placeholder="Argentina"
                  required
                />

                <Select
                  options={birthYearOptions}
                  title="Año de nacimiento"
                  handleChange={handleChange}
                  name="birthDate"
                  value={values.birthDate}
                  placeholder="Selecciona"
                  className={errors.birthDate ? 'border-red-700' : ''}
                  errors={errors.birthDate}
                  required
                />

                <div className="my-8">
                  <h1 className="block  font-regular my-4 text-black text-md text-left">
                    ¿Cuál es tu Etnia?<span className="text-red-600"> *</span>
                  </h1>
                  {ethnicityOptions.map((option) => (
                    <Radio
                      key={option.id}
                      value={option.value}
                      text={option.text}
                      name={option.name}
                      handleChange={handleChange}
                    />
                  ))}
                  {errors.ethnicity && (
                    <p className="text-red-700 my-3 text-left">{errors.ethnicity}</p>
                  )}
                </div>

                <div className="w-full my-8 grid grid-cols-1 sm:grid-cols-2 sm:gap-5">
                  <div>
                    <h1 className="block font-regular my-4 text-black text-md text-left">
                      ¿Tienes alguna discapacidad?
                    </h1>
                    {yesNoOptionsDisability.map((option) => (
                      <Radio
                        className="capitalize"
                        key={option.value}
                        value={option.value}
                        text={option.value}
                        name={option.name}
                        handleChange={handleChange}
                      />
                    ))}
                  </div>

                  <div>
                    <h1 className="block font-regular my-4 text-black text-md text-left">
                      ¿De qué tipo?
                    </h1>
                    {disabilityOptions.map((option) => (
                      <Radio
                        key={option.value}
                        value={option.value}
                        text={option.value}
                        name={option.name}
                        handleChange={handleChange}
                        disabled={values.disability === '' || values.disability === 'no'}
                      />
                    ))}
                  </div>
                </div>

                {!!Object.keys(errors).length && (
                  <p className="text-red-700 my-12 text-center ">
                    Por favor revisa que todos los campos estén completos
                  </p>
                )}
                <Button type="submit">Continuar</Button>
              </form>
            )}
          </Formik>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default FormJuegoDeRespuestaAutomatica;
