import { Header } from '../components';

const NotFoundView = () => {
  return (
    <div className="h-screen grid place-items-center">
      <div>
        <Header />
        <h1 className="text-9xl text-center">404</h1>
        <p className="text-primary text-center sm:text-2xl">
          La pagina que esta buscando no existe
        </p>
      </div>
    </div>
  );
};

export default NotFoundView;
