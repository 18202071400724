export const FLOW_NOT_STARTED = 'flowNotStarted';

export const FLOW_STARTED = 'flowStarted';
export const FLOW_JRA_INTRO_VIEWED = 'introJuegoDeRespuestaAutomaticaViewed';
export const FLOW_JRA_FORM_COMPLETED = 'completedJuegoDeRespuestaAutomaticaForm';
export const FLOW_JRA_IMAGES_LOADED = 'loadedJuegoDeRespuestaAutomaticaImages';

export const FLOW_JRA_FINISHED = 'finishedJuegoDeRespuestaAutomatica';

export const FLOW_JDP_LANDING_VIEWED = 'viewedJuegoDePalabrasLanding';
export const FLOW_JDP_FORM_COMPLETED = 'completedFormJuegoDePalabras';
export const FLOW_JDP_FINISHED = 'finishedJuegoDePalabras';
export const FLOW_JDP_NO_WORDS_SELECTED = 'noWordsSelectedJuegoDePalabras';
export const FLOW_JDP_NO_WORDS_SELECTED_WARNING_SEEN = 'noWordsSelectedWarningSeenJuegoDePalabras';
export const FLOW_JDP_MODIFICATION_ANSWERS_STARTED = 'startedModificationJuegoDePalabrasAnswers';
export const FLOW_JDP_CONFIRMED_ANSWERS = 'confirmedJuegoDePalabrasAnswers';

export const FLOW_FINISHED = 'finishedWholeFlow';
