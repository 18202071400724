import React from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';
import ReactTooltip from 'react-tooltip';
import { Radar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { tooltips } from '../../consts/stats';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const RadarChart = ({ data }) => {
  if (!data) return null;

  const radarData = {
    ...data,
    datasets: data.datasets.map((dataset) => ({ ...dataset, backgroundColor: 'transparent' }))
  };

  return (
    <div className="grid md:grid-cols-2 mx-auto md:flex-row max-w-screen  md:p-5 md:my-8 ">
      <div className="min-w-[350px] w-full max-w-[450px] mx-auto">
        <Radar data={radarData} />
      </div>
      <div className="p-8">
        <div className="flex md:my-5 gap-x-1 text-xl">
          <div>
            <span className="flex items-end justify-end flex-col">
              {radarData?.datasets[1]?.data.map((value, index) => (
                <span key={index}>{Math.floor(value)}%</span>
              ))}
            </span>
          </div>
          <div className="flex flex-col">
            {data?.labels.map((label, index) => (
              <React.Fragment key={index}>
                <span className="flex ml-1" key={label}>
                  de{' '}
                  <p
                    data-tip
                    data-for={label.toLowerCase()}
                    className="uppercase ml-1 font-semibold cursor-pointer">
                    {label.toLowerCase()}
                  </p>
                </span>
                <ReactTooltip
                  id={label.toLowerCase()}
                  effect="solid"
                  backgroundColor="#94A3B8"
                  place="top">
                  <div className="w-auto mx-auto max-w-md text-center p-0 bg-slate-400 text-white">
                    {
                      tooltips.find(
                        (tooltip) => tooltip.title.toLowerCase() === label.toLowerCase()
                      )?.text
                    }
                  </div>
                </ReactTooltip>
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="flex items-center">
          <div className="shrink-0 w-8 h-8 my-3 bg-[#FF4C00]"></div>
          <p className="ml-5 text-md font-regular ">
            La línea roja del gráfico te representa a tí.
          </p>
        </div>
        <div className="flex items-center">
          <div className="shrink-0 w-8 h-8 my-3 bg-[#008AAB]"></div>
          <p className="ml-5 text-md font-regular">
            La línea azul del gráfico representa cómo respondieron las demás personas de la
            organización
          </p>
        </div>
      </div>
    </div>
  );
};

export default RadarChart;

RadarChart.propTypes = {
  data: PropTypes.object
};
