import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Radio } from '../../../components';
import {
  containedByOrganizationOptions,
  proudOfWorkThereOptions,
  sameOrganizationPurposeOptions,
  societyImpactOptions
} from '../../../consts/juegoDePalabras/consts';
import { validate } from '../../../helpers';
import { setParticipantFeelingsAboutOrganizations } from '../../../store/slices/juegoDePalabras';
import { setUserFlowStep } from '../../../store/slices/userFlow';
import { FLOW_JDP_FORM_COMPLETED } from '../../../consts/userFlowSteps';

const FormJuegoDePalabras = () => {
  const initialValues = {
    containedByOrganization: '',
    proudOfWorkThere: '',
    sameOrganizationPurpose: '',
    societyImpact: ''
  };

  const dispatch = useDispatch();

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => {
          dispatch(setParticipantFeelingsAboutOrganizations(values));
          dispatch(setUserFlowStep(FLOW_JDP_FORM_COMPLETED));
        }}>
        {({ errors, handleSubmit, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <div className="mt-10">
              <h1 className="mb-6">¿Sientes que la cultura de tu organización te contiene? *</h1>
              {containedByOrganizationOptions.map((option) => (
                <Radio
                  key={option.value}
                  value={option.value}
                  text={option.text}
                  name={option.name}
                  handleChange={handleChange}
                />
              ))}
              {errors.containedByOrganization && (
                <p className="text-red-700 my-3 text-left">{errors.containedByOrganization}</p>
              )}
            </div>
            <div className="mt-20">
              <h1 className="mb-6">¿Te sientes orgulloso de trabajar en esta organización? *</h1>
              {proudOfWorkThereOptions.map((option) => (
                <Radio
                  key={option.value}
                  value={option.value}
                  text={option.text}
                  name={option.name}
                  handleChange={handleChange}
                />
              ))}
              {errors.proudOfWorkThere && (
                <p className="text-red-700 my-3 text-left">{errors.proudOfWorkThere}</p>
              )}
            </div>
            <div className="mt-20">
              <h1 className="mb-6">
                ¿Sientes que la cultura de tu organización persigue un propósito alineado al tuyo? *
              </h1>
              {sameOrganizationPurposeOptions.map((option) => (
                <Radio
                  key={option.value}
                  value={option.value}
                  text={option.text}
                  name={option.name}
                  handleChange={handleChange}
                />
              ))}
              {errors.sameOrganizationPurpose && (
                <p className="text-red-700 my-3 text-left">{errors.sameOrganizationPurpose}</p>
              )}
            </div>
            <div className="mt-20">
              <h1 className="mb-6">
                ¿Piensas que tu organización está provocando un impacto en la sociedad? *
              </h1>
              {societyImpactOptions.map((option) => (
                <Radio
                  key={option.value}
                  value={option.value}
                  text={option.text}
                  name={option.name}
                  handleChange={handleChange}
                />
              ))}
              {errors.societyImpact && (
                <p className="text-red-700 my-3 text-left">{errors.societyImpact}</p>
              )}
            </div>
            {Object.keys(errors).length ? (
              <p className="text-red-700  mt-12 text-center ">
                Por favor chequeá que todos los campos estén completos
              </p>
            ) : null}
            <Button customMargin="mx-auto mt-8" type="submit">
              Comenzar a jugar
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default FormJuegoDePalabras;
