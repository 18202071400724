/* eslint-disable react/no-unescaped-entities */
import { Header, Footer } from '../components';

function PreguntasFrecuentes() {
  return (
    <div className="h-screen flex flex-col justify-between">
      <Header />
      <div>
        <h1 className="text-4xl text-primary w-full text-center my-4 sm:my-8">
          Preguntas Frecuentes
        </h1>
        <div className="text-md sm:text-lg w-5/6 mx-auto">
          <p dir="ltr">¿Qué es Humanin Lab?</p>
          <br />
          <p dir="ltr">
            Humanin Lab, inteligencia de datos en DEI, es una start up que a través de juegos
            detecta datos relevantes en Diversidad, Equidad e Inclusión que aún las organizaciones
            no tienen disponible. El primer desarrollo de Humanin Lab es un Juego de Sesgos
            Implícitos de Diversidad que mide la igualdad de oportunidades de las mujeres en
            posiciones de decisión y el estilo de liderazgo aceptado en la organización de acuerdo a
            ciertos estereotipos culturales.
          </p>
          <br />
          <br />
          <p dir="ltr">¿Qué datos arroja el Juego de Sesgos Implícitos de Diversidad?</p>
          <br />
          <p dir="ltr">
            El juego arrojará la cantidad de tiempo que utilizó el usuario en cada etapa del mismo
            en asignar la imagen o palabra a la categoría correcta que aparece en la pantalla. La
            prueba que estamos realizando en este momento nos permitirá con su ayuda construir el
            algoritmo que luego nos permitirá obtener un reporte automático para cada usuario.
          </p>
          <br />
          <p dir="ltr">¿Voy a ver mi resultado al finalizar el juego ?</p>
          <br />
          <p dir="ltr">
            En esta etapa no automáticamente. Una vez obtenido el algoritmo enviaremos el resultado
            del juego a cada una de las personas que se prestaron a realizar la prueba.
          </p>
          <br />
          <br />
          <p dir="ltr">¿Puede ser que no me vea reflejado en el resultado?</p>
          <br />
          <p dir="ltr">
            Probablemente sí. El resultado de una prueba IAT no es determinante de quienes somos.
            Las pruebas IAT no son una respuesta definitiva sino una tendencia sobre cómo de forma
            inconsciente seleccionamos cierta información. Tener sesgos es humano y no determina si
            somos buenas o malas personas.
          </p>
          <br />
          <p dir="ltr">¿Puedo repetir el juego si no me gusta el resultado?</p>
          <br />
          <p dir="ltr">
            Nuestra recomendación es que no de forma inmediata. Es importante analizar el resultado
            y ejercer una cierta auto-observación acerca de si el resultado se corresponde o no con
            lo que pensamos o elegiríamos. Al ser una prueba de respuesta automática tiene mayor
            validez la primera respuesta.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PreguntasFrecuentes;
