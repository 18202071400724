import React from 'react';
import logo from '../assets/logo.png';

const Loader = () => {
  return (
    <div className="h-screen w-screen grid place-items-center">
      <img src={logo} alt="logo" className="w-60 animate-pulse" draggable={false} />
    </div>
  );
};

export default Loader;
