import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentStepSelectedWords,
  setCurrentStepRemainingWords,
  setAllRemainingWords,
  setAllSelectedWords,
  addStep
} from '../../../store/slices/juegoDePalabras';
import { Button, Checkbox, Header } from '../../../components';
import { setUserFlowStep } from '../../../store/slices/userFlow';
import { FLOW_JDP_FINISHED, FLOW_JDP_NO_WORDS_SELECTED } from '../../../consts/userFlowSteps';

const JuegoDePalabrasGame = () => {
  const dispatch = useDispatch();
  const {
    wordsReceivedFromApi,
    step,
    allSelectedWords,
    currentStepSelectedWords,
    currentStepRemainingWords,
    allRemainingWords
  } = useSelector((state) => state.juegoDePalabras);

  const [buttonIsDisabled, setButtonIsDisabled] = useState(true);

  const handleContinueButtonClick = () => {
    dispatch(addStep());
    if (step === 5) {
      dispatch(setUserFlowStep(FLOW_JDP_FINISHED));
    }
    if (step === 5 && allSelectedWords.length < 2) {
      dispatch(setUserFlowStep(FLOW_JDP_NO_WORDS_SELECTED));
    }
    setButtonIsDisabled(true);
  };

  const handleSelectWordsChange = (word) => {
    dispatch(setCurrentStepSelectedWords([...currentStepSelectedWords, word]));

    if (currentStepSelectedWords.includes(word)) {
      dispatch(setCurrentStepSelectedWords(currentStepSelectedWords.filter((w) => w !== word)));
    }
  };

  useEffect(() => {
    dispatch(
      setCurrentStepRemainingWords(
        wordsReceivedFromApi[step].filter((w) => !currentStepSelectedWords.includes(w))
      )
    );
  }, [currentStepSelectedWords]);

  useEffect(() => {
    if (step > 0) {
      dispatch(setAllSelectedWords([...allSelectedWords, currentStepSelectedWords].flat()));
      dispatch(setAllRemainingWords([...allRemainingWords, currentStepRemainingWords].flat()));

      dispatch(setCurrentStepSelectedWords([]));
      dispatch(setCurrentStepRemainingWords([]));
    }

    if (buttonIsDisabled) {
      const timer = setTimeout(() => {
        setButtonIsDisabled(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [step, buttonIsDisabled]);

  return (
    <div className="h-screen flex flex-col justify-between">
      <div>
        <Header />
        <h1 className="text-center text-principal text-md mb-4 sm:my-0 sm:text-lg w-5/6 sm:w-3/6 mx-auto">
          Selecciona un máximo de tres palabras siempre y cuándo sientas que{' '}
          <strong className="font-bold">representan</strong> la cultura de tu organización.
        </h1>
      </div>

      <main className="grid my-8 gap-x-3 grid-cols-2 w-auto mx-4 sm:w-3/6 sm:mx-auto gap-y-8 uppercase sm:gap-x-4">
        {step < 7 &&
          wordsReceivedFromApi[step]?.map((word) => (
            <Checkbox
              key={word}
              name="word"
              value={word}
              text={word}
              handleChange={() => handleSelectWordsChange(word)}
              disabled={
                currentStepSelectedWords.length >= 3 && !currentStepSelectedWords.includes(word)
              }
              className={`text-2xl ${currentStepSelectedWords.length >= 3 ? 'opacity-50' : ''}`}
              game="jdp"
            />
          ))}
      </main>
      <div>
        <Button onClick={handleContinueButtonClick} disabled={buttonIsDisabled} up>
          {step < 6 ? 'Continuar con el siguiente grupo' : 'Ver mi lista de palabras seleccionadas'}
        </Button>
      </div>
    </div>
  );
};

export default JuegoDePalabrasGame;
