import React, { useEffect, useState } from 'react';
import { Button, Header, LayoutDecorativeFigure, Notification } from '../../../components';
import { useDispatch } from 'react-redux';
import { setUserFlowStep } from '../../../store/slices/userFlow';
import { FLOW_JDP_LANDING_VIEWED } from '../../../consts/userFlowSteps';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { NotificationType } from '../../../components/Notification';

const REMINDER_MESSAGES = {
  [NotificationType.SUCCESS]: 'Recordatorio enviado exitosamente',
  [NotificationType.ERROR]: 'Error al enviar recordatorio'
};

const LandingJuegoDePalabras = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [apiStatus, setApiStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const handleComenzarSegundoJuegoButtonClick = () => {
    dispatch(setUserFlowStep(FLOW_JDP_LANDING_VIEWED));
  };

  const gameId = pathname.split('/game/')[1];

  const sendReminder = async () => {
    setLoading(true);
    try {
      axios.post(`${process.env.REACT_APP_API_URL}/sendReminder/${gameId}`, {});
      setApiStatus(NotificationType.SUCCESS);
      setLoading(false);
    } catch (error) {
      setApiStatus(NotificationType.ERROR);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (apiStatus) {
      const timer = setTimeout(() => {
        setApiStatus('');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [apiStatus]);

  const sendReminderNotification = apiStatus && REMINDER_MESSAGES[apiStatus];

  return (
    <div>
      <Header />

      <LayoutDecorativeFigure>
        <div className="text-left text-secondary px-16 md:p-8 w-full sm:px-0 md:w-4/6 mx-auto my-12">
          <h3
            className="my-3 md:mt-7 font-semibold"
            style={{
              fontFamily: 'Urbanist',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '24px',
              color: '#E53D3D'
            }}>
            Gracias por completar esta etapa...
            <br />
            <br />
            <span className="font-bold">¿Estás listo para el segundo juego? ¡Vamos a ello!</span>
          </h3>
          <p className="mt-10 sm:mb-16 font-light text-md text-secondary">
            Recuerda que derberás realizar este juego de una sola vez.
            <br />
            Una vez finalizado este juego podrás visualizar tus resultados.
          </p>
          <section className="flex flex-col mt-8 md:mt-12 mb-4 sm:flex-row justify-between">
            <Button
              partial
              customPadding={'py-0 md:py-4'}
              customMargin={'mt-6 mx-auto sm:mx-0'}
              customWidth={'w-full'}
              onClick={() => handleComenzarSegundoJuegoButtonClick()}>
              Comenzar segundo juego
            </Button>
            <Button
              disabled={loading}
              partial
              customPadding={'py-0 md:py-4'}
              customMargin={'mt-0 md:mt-6 mx-auto sm:mx-0'}
              customWidth={'w-full'}
              customTextColor={'text-black'}
              variant={'link'}
              onClick={() => sendReminder()}>
              Recuérdamelo para hacerlo mas tarde
            </Button>
          </section>

          {sendReminderNotification && (
            <Notification type={apiStatus} message={sendReminderNotification} />
          )}
        </div>
      </LayoutDecorativeFigure>
    </div>
  );
};

export default LandingJuegoDePalabras;
