/* eslint-disable react/prop-types */

import React from 'react';
import cx from 'classnames';

const Input = ({
  title,
  type,
  handleChange,
  value,
  name,
  placeholder,
  className,
  onKeyDown,
  errors,
  disabled,
  noLabel,
  required = false
}) => {
  return (
    <div className={cx('my-5', className)}>
      {!noLabel ? (
        <label htmlFor={value} className="block font-medium my-5 text-black text-md text-left">
          {title}
          {required && <span className="text-red-600"> *</span>}
        </label>
      ) : null}
      <input
        value={value}
        type={type}
        name={name}
        id={value}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        className={`py-3 block w-full h-16 ${
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        } text-gray-700  sm:mx-auto border border-gray-100 rounded-full	shadow-sm cursor-pointer focus:outline-none focus:ring-primary focus:border-principal sm:text-sm  ${className}`}
        placeholder={placeholder}
        disabled={disabled}
      />

      {errors && <p className="text-red-500 text-sm">{errors}</p>}
    </div>
  );
};

export default Input;
