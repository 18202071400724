/* eslint-disable no-undef */
import { createSlice } from '@reduxjs/toolkit';

export const juegoDeRespuestaAutomaticaSlice = createSlice({
  name: 'juegoDeRespuestaAutomatica',
  initialState: {
    participant: undefined,
    answers: [],
    questions: [],
    images: [],
    step: 0
  },
  reducers: {
    addAnswer: (state, action) => {
      state.answers.push(action.payload);
    },
    setParticipantData: (state, action) => {
      state.participant = action.payload;
    },
    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
    setImages: (state, action) => {
      state.images = action.payload;
    },
    addStep: (state) => {
      state.step = state.step + 1;
    }
  }
});

export const { addAnswer, setParticipantData, setQuestions, setImages, addStep } =
  juegoDeRespuestaAutomaticaSlice.actions;

export default juegoDeRespuestaAutomaticaSlice.reducer;
