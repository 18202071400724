import { createSlice } from '@reduxjs/toolkit';
import { FLOW_NOT_STARTED } from '../../consts/userFlowSteps';

export const userFlowSlice = createSlice({
  name: 'userFlow',
  initialState: {
    userFlowStep: FLOW_NOT_STARTED,
    gameApiError: undefined,
    loading: false
  },
  reducers: {
    setUserFlowStep: (state, action) => {
      state.userFlowStep = action.payload;
    },
    setGameApiError: (state, action) => {
      state.gameApiError = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    }
  }
});

export const { setUserFlowStep, setGameApiError, setLoading } = userFlowSlice.actions;

export default userFlowSlice.reducer;
