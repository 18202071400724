import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserFlowStep } from '../../../store/slices/userFlow';
import { FLOW_JRA_INTRO_VIEWED } from '../../../consts/userFlowSteps';

import { Button, Footer, Header } from '../../../components';
import instructions from '../../../assets/instructions.png';
import { categories } from '../../../consts/juegoDeRespuestaAutomatica/categories';

const GameDescription = () => {
  const dispatch = useDispatch();

  const handleContinuarButtonClick = () => {
    dispatch(setUserFlowStep(FLOW_JRA_INTRO_VIEWED));
  };

  // Scroll up to top on page load (mainly for mobile)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full min-h-screen flex flex-col justify-between items-center text-principal">
      <Header isFull={true} />
      <div className="text-left px-16 w-full mx-auto my-12 sm:px-0 md:w-4/6 md:p-8 md:px-28 sm:mt-0">
        <h1 className="text-2xl font-semibold">Juego de Respuesta Automática</h1>
        <p className="mt-5">
          <span className="font-semibold">
            ¿Sabes cuál es tu asociación automática cuando piensas en C-Level? ¿ A qué perfil lo
            asocias? ¿A quién o con qué asocias palabras como empatía o pragmatismo?
          </span>
          <br />
          <br />
          El juego de Respuesta Automática te ayudará a conocer cuáles son las asociaciones
          implícitas que te llevan a dar respuestas automáticas cuando se te presentan diferentes
          categorías que debes relacionar.
          <br />
          <br />
          <strong className="text-red-600">Ten en cuenta:</strong> clickear la respuesta correcta en
          el menor tiempo posible es crucial para medir asociaciones implícitas.
        </p>
      </div>

      <div className="w-full sm:w-5/6 mx-auto">
        <div className="mb-20 rounded-3xl px-16 md:bg-wordGameBack md:border-2">
          <h2 className="text-xl md:text-2xl font-semibold text-center my-10 h-100">
            ¿Cómo funciona el Juego de Respuesta Automática?
          </h2>
          <div className="md:grid md:grid-cols-2 mb-20">
            <div className="mt-10">
              <img
                src={instructions}
                draggable={false}
                className="max-w-full mb-4"
                style={{ width: 'auto', height: 'auto' }}
                alt="instructions"
              />

              <p className="mb-10 md:px-10">
                En la pantalla aparecerá una imagen o palabra que deberás asociar a la categoría
                correcta tal como se muestra en la imagen
              </p>
            </div>
            <div>
              <h3>Deberás asociar en el menor tiempo posible:</h3>
              <div className="my-5 w-full leading-3 h-full mx-auto md:grid md:grid-cols-2 gap-x-20">
                {categories.map((cat) => (
                  <div
                    key={cat.id}
                    className=" w-5/6 sm:w-full mx-auto min-h-48 py-3 flex flex-col">
                    <span className="text-lg bg-title text-white py-2 rounded-xl text-center font-regular mb-4 ">
                      {cat.name}
                    </span>
                    <p
                      className="text-sm text-secondary text-center"
                      dangerouslySetInnerHTML={{ __html: cat.text }}></p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-left px-16 w-full mx-auto my-12 sm:px-0 md:w-4/6 md:p-8 md:px-28 sm:mt-0">
        <p>
          Las <strong>categorías</strong> irán <strong>cambiando o se agruparán</strong> a medida
          que avances en el juego.
          <br />
          <br />
          Para <strong>poder avanzar</strong> siempre deberás <strong>clickear</strong> sobre la{' '}
          <strong className="text-green-600">categoría correcta.</strong>
          <br />
          <br />
          Si <strong>no</strong> clickeaste la <strong>opción correcta</strong>, el juego te lo
          indicará <strong>enmarcándola de color rojo</strong>.
          <br />
          <br />
          <strong>Recuerda responder lo más rápido</strong> posible y{' '}
          <strong>sin interrupciones</strong> ya que <strong>el tiempo es determinante</strong> en
          el <strong>resultado</strong>.
          <br />
          <br />
          <br />
          <strong>
            <span className="text-red-600">No te preocupes</span>, no debes acordarte de todo. Te lo
            iremos recordando en cada etapa del juego.
          </strong>
        </p>
      </div>

      <Button onClick={() => handleContinuarButtonClick()}>Continuar</Button>

      <Footer />
    </div>
  );
};

export default GameDescription;
