import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Header } from '../../../components';
import { setUserFlowStep } from '../../../store/slices/userFlow';
import {
  FLOW_JDP_MODIFICATION_ANSWERS_STARTED,
  FLOW_JDP_CONFIRMED_ANSWERS
} from '../../../consts/userFlowSteps';
import SelectedWords from './SelectedWords';

const AnswersReview = () => {
  const dispatch = useDispatch();
  const { allSelectedWords } = useSelector((state) => state.juegoDePalabras);
  return (
    <div className="h-screen flex flex-col justify-between w-full px-10 mx-auto sm:w-5/6">
      <Header />

      <div className="pb-3 md:py-5">
        <SelectedWords
          words={allSelectedWords}
          title={
            <>
              Estas son tus <strong className="font-bold">palabras</strong> seleccionadas:
            </>
          }
        />
      </div>

      <div className="flex flex-row gap-x-6 justify-center mx-auto my-10 md:my-24">
        <Button
          customWidth="w-full md:w-52"
          variant="secondary"
          partial
          onClick={() => dispatch(setUserFlowStep(FLOW_JDP_MODIFICATION_ANSWERS_STARTED))}>
          Modificar mi selección
        </Button>
        <Button
          customWidth="w-full md:w-52"
          partial
          onClick={() => dispatch(setUserFlowStep(FLOW_JDP_CONFIRMED_ANSWERS))}>
          No modificar mi seleccion y continuar
        </Button>
      </div>
    </div>
  );
};

export default AnswersReview;
