export const yearOptions = [
  { value: '2004', name: 'years' },
  { value: '2003', name: 'years' },
  { value: '2002', name: 'years' },
  { value: '2001', name: 'years' },
  { value: '2000', name: 'years' },
  { value: '1999', name: 'years' },
  { value: '1998', name: 'years' },
  { value: '1997', name: 'years' },
  { value: '1996', name: 'years' },
  { value: '1995', name: 'years' },
  { value: '1994', name: 'years' },
  { value: '1993', name: 'years' },
  { value: '1992', name: 'years' },
  { value: '1991', name: 'years' },
  { value: '1990', name: 'years' },
  { value: '1989', name: 'years' },
  { value: '1988', name: 'years' },
  { value: '1987', name: 'years' },
  { value: '1986', name: 'years' },
  { value: '1985', name: 'years' },
  { value: '1984', name: 'years' },
  { value: '1983', name: 'years' },
  { value: '1982', name: 'years' },
  { value: '1981', name: 'years' },
  { value: '1980', name: 'years' },
  { value: '1979', name: 'years' },
  { value: '1978', name: 'years' },
  { value: '1977', name: 'years' },
  { value: '1976', name: 'years' },
  { value: '1975', name: 'years' },
  { value: '1974', name: 'years' },
  { value: '1973', name: 'years' },
  { value: '1972', name: 'years' },
  { value: '1971', name: 'years' },
  { value: '1970', name: 'years' },
  { value: '1969', name: 'years' },
  { value: '1968', name: 'years' },
  { value: '1967', name: 'years' },
  { value: '1966', name: 'years' },
  { value: '1965', name: 'years' },
  { value: '1964', name: 'years' },
  { value: '1963', name: 'years' },
  { value: '1962', name: 'years' },
  { value: '1961', name: 'years' },
  { value: '1960', name: 'years' },
  { value: '1959', name: 'years' },
  { value: '1958', name: 'years' },
  { value: '1957', name: 'years' },
  { value: '1956', name: 'years' },
  { value: '1955', name: 'years' },
  { value: '1954', name: 'years' },
  { value: '1953', name: 'years' },
  { value: '1952', name: 'years' },
  { value: '1951', name: 'years' },
  { value: '1950', name: 'years' },
  { value: '1949', name: 'years' },
  { value: '1948', name: 'years' },
  { value: '1947', name: 'years' },
  { value: '1946', name: 'years' },
  { value: '1945', name: 'years' },
  { value: '1944', name: 'years' },
  { value: '1943', name: 'years' },
  { value: '1942', name: 'years' },
  { value: '1941', name: 'years' },
  { value: '1940', name: 'years' },
  { value: '1939', name: 'years' },
  { value: '1938', name: 'years' },
  { value: '1937', name: 'years' },
  { value: '1936', name: 'years' },
  { value: '1935', name: 'years' },
  { value: '1934', name: 'years' },
  { value: '1933', name: 'years' },
  { value: '1932', name: 'years' },
  { value: '1931', name: 'years' },
  { value: '1930', name: 'years' },
  { value: '1929', name: 'years' },
  { value: '1928', name: 'years' },
  { value: '1927', name: 'years' },
  { value: '1926', name: 'years' },
  { value: '1925', name: 'years' },
  { value: '1924', name: 'years' },
  { value: '1923', name: 'years' },
  { value: '1922', name: 'years' },
  { value: '1921', name: 'years' },
  { value: '1920', name: 'years' }
];

export const genderOptions = [
  { id: 1, value: 'masculino', name: 'gender', text: 'masculino', checked: true },
  { id: 2, value: 'femenino', name: 'gender', text: 'femenino' },
  { id: 3, value: 'intersexual', name: 'gender', text: 'intersexual/No específico' }
];

export const identificationOptions = [
  { id: 3, value: 'Hombre/Masculino', name: 'identification', text: 'Hombre/Masculino' },
  { id: 4, value: 'Mujer/Femenino', name: 'identification', text: 'Mujer/Femenino' },
  {
    id: 5,
    value: 'Varón Trans',
    name: 'identification',
    text: 'Varón Trans'
  },
  {
    id: 6,
    value: ' Mujer Trans',
    name: 'identification',
    text: ' Mujer Trans'
  },
  {
    id: 7,
    value: 'Queer',
    name: 'identification',
    text: 'Queer'
  },
  {
    id: 8,
    value: 'Sin identificación de Género',
    name: 'identification',
    text: 'Sin identificación de Género'
  },
  {
    id: 9,
    value: 'Género Fluido',
    name: 'identification',
    text: 'Género Fluido'
  },
  { id: 10, value: 'Otra', name: 'identification', text: 'Otra' }
];

export const sexualOrientationOptions = [
  { id: 9, value: 'Heterosexual', name: 'sexualOrientation', text: 'Heterosexual' },
  { id: 10, value: 'Bisexual', name: 'sexualOrientation', text: 'Bisexual' },
  { id: 11, value: 'Homosexual', name: 'sexualOrientation', text: 'Homosexual' },
  { id: 12, value: 'Asexual', name: 'sexualOrientation', text: 'Asexual' },
  { id: 13, value: 'OTRA', name: 'sexualOrientation', text: 'Otra' }
];

export const raceOptions = [
  { id: 15, value: 'Blanco', name: 'race', text: 'Blanco' },
  { id: 16, value: 'Negro/Afro descendiente', name: 'race', text: 'Negro/Afro descendiente' },
  { id: 17, value: 'Originario/Nativo', name: 'race', text: 'Originario/Nativo' },
  { id: 18, value: 'Sud Asiatico ', name: 'race', text: 'Sud Asiatico ' },
  { id: 19, value: 'Este Asíatico', name: 'race', text: 'Este Asíatico' },
  { id: 20, value: 'Multiracial', name: 'race', text: 'Multiracial' },
  { id: 21, value: 'Otra/desconozco', name: 'race', text: 'Otra/desconozco' }
];

export const ethnicityOptions = [
  {
    id: 22,
    value: 'Europeo',
    name: 'ethnicity',
    text: 'Europeo',
    checked: true
  },
  { id: 23, value: 'Latino', name: 'ethnicity', text: 'Latino' },
  { id: 24, value: 'Afrodescendiente', name: 'ethnicity', text: 'Afrodescendiente' },
  { id: 25, value: 'Originario', name: 'ethnicity', text: 'Originario' },
  { id: 26, value: 'Oriental', name: 'ethnicity', text: 'Oriental' },
  { id: 27, value: 'Multirracial', name: 'ethnicity', text: 'Multirracial' }
];

export const countryOptions = [
  { name: 'country', value: 'Albania', code: 'AL' },
  { name: 'country', value: 'Åland Islands', code: 'AX' },
  { name: 'country', value: 'Algeria', code: 'DZ' },
  { name: 'country', value: 'American Samoa', code: 'AS' },
  { name: 'country', value: 'Andorra', code: 'AD' },
  { name: 'country', value: 'Angola', code: 'AO' },
  { name: 'country', value: 'Anguilla', code: 'AI' },
  { name: 'country', value: 'Antarctica', code: 'AQ' },
  { name: 'country', value: 'Antigua and Barbuda', code: 'AG' },
  { name: 'country', value: 'Argentina', code: 'AR' },
  { name: 'country', value: 'Armenia', code: 'AM' },
  { name: 'country', value: 'Aruba', code: 'AW' },
  { name: 'country', value: 'Australia', code: 'AU' },
  { name: 'country', value: 'Austria', code: 'AT' },
  { name: 'country', value: 'Azerbaijan', code: 'AZ' },
  { name: 'country', value: 'Bahamas (the)', code: 'BS' },
  { name: 'country', value: 'Bahrain', code: 'BH' },
  { name: 'country', value: 'Bangladesh', code: 'BD' },
  { name: 'country', value: 'Barbados', code: 'BB' },
  { name: 'country', value: 'Belarus', code: 'BY' },
  { name: 'country', value: 'Belgium', code: 'BE' },
  { name: 'country', value: 'Belize', code: 'BZ' },
  { name: 'country', value: 'Benin', code: 'BJ' },
  { name: 'country', value: 'Bermuda', code: 'BM' },
  { name: 'country', value: 'Bhutan', code: 'BT' },
  { name: 'country', value: 'Bolivia (Plurinational State of)', code: 'BO' },
  { name: 'country', value: 'Bonaire, Sint Eustatius and Saba', code: 'BQ' },
  { name: 'country', value: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'country', value: 'Botswana', code: 'BW' },
  { name: 'country', value: 'Bouvet Island', code: 'BV' },
  { name: 'country', value: 'Brazil', code: 'BR' },
  { name: 'country', value: 'British Indian Ocean Territory (the)', code: 'IO' },
  { name: 'country', value: 'Brunei Darussalam', code: 'BN' },
  { name: 'country', value: 'Bulgaria', code: 'BG' },
  { name: 'country', value: 'Burkina Faso', code: 'BF' },
  { name: 'country', value: 'Burundi', code: 'BI' },
  { name: 'country', value: 'Cabo Verde', code: 'CV' },
  { name: 'country', value: 'Cambodia', code: 'KH' },
  { name: 'country', value: 'Cameroon', code: 'CM' },
  { name: 'country', value: 'Canada', code: 'CA' },
  { name: 'country', value: 'Cayman Islands (the)', code: 'KY' },
  { name: 'country', value: 'Central African Republic (the)', code: 'CF' },
  { name: 'country', value: 'Chad', code: 'TD' },
  { name: 'country', value: 'Chile', code: 'CL' },
  { name: 'country', value: 'China', code: 'CN' },
  { name: 'country', value: 'Christmas Island', code: 'CX' },
  { name: 'country', value: 'Cocos (Keeling) Islands (the)', code: 'CC' },
  { name: 'country', value: 'Colombia', code: 'CO' },
  { name: 'country', value: 'Comoros (the)', code: 'KM' },
  { name: 'country', value: 'Congo (the Democratic Republic of the)', code: 'CD' },
  { name: 'country', value: 'Congo (the)', code: 'CG' },
  { name: 'country', value: 'Cook Islands (the)', code: 'CK' },
  { name: 'country', value: 'Costa Rica', code: 'CR' },
  { name: 'country', value: 'Croatia', code: 'HR' },
  { name: 'country', value: 'Cuba', code: 'CU' },
  { name: 'country', value: 'Curaçao', code: 'CW' },
  { name: 'country', value: 'Cyprus', code: 'CY' },
  { name: 'country', value: 'Czechia', code: 'CZ' },
  { name: 'country', value: "Côte d'Ivoire", code: 'CI' },
  { name: 'country', value: 'Denmark', code: 'DK' },
  { name: 'country', value: 'Djibouti', code: 'DJ' },
  { name: 'country', value: 'Dominica', code: 'DM' },
  { name: 'country', value: 'Dominican Republic (the)', code: 'DO' },
  { name: 'country', value: 'Ecuador', code: 'EC' },
  { name: 'country', value: 'Egypt', code: 'EG' },
  { name: 'country', value: 'El Salvador', code: 'SV' },
  { name: 'country', value: 'Equatorial Guinea', code: 'GQ' },
  { name: 'country', value: 'Eritrea', code: 'ER' },
  { name: 'country', value: 'Estonia', code: 'EE' },
  { name: 'country', value: 'Eswatini', code: 'SZ' },
  { name: 'country', value: 'Ethiopia', code: 'ET' },
  { name: 'country', value: 'Falkland Islands (the) [Malvinas]', code: 'FK' },
  { name: 'country', value: 'Faroe Islands (the)', code: 'FO' },
  { name: 'country', value: 'Fiji', code: 'FJ' },
  { name: 'country', value: 'Finland', code: 'FI' },
  { name: 'country', value: 'France', code: 'FR' },
  { name: 'country', value: 'French Guiana', code: 'GF' },
  { name: 'country', value: 'French Polynesia', code: 'PF' },
  { name: 'country', value: 'French Southern Territories (the)', code: 'TF' },
  { name: 'country', value: 'Gabon', code: 'GA' },
  { name: 'country', value: 'Gambia (the)', code: 'GM' },
  { name: 'country', value: 'Georgia', code: 'GE' },
  { name: 'country', value: 'Germany', code: 'DE' },
  { name: 'country', value: 'Ghana', code: 'GH' },
  { name: 'country', value: 'Gibraltar', code: 'GI' },
  { name: 'country', value: 'Greece', code: 'GR' },
  { name: 'country', value: 'Greenland', code: 'GL' },
  { name: 'country', value: 'Grenada', code: 'GD' },
  { name: 'country', value: 'Guadeloupe', code: 'GP' },
  { name: 'country', value: 'Guam', code: 'GU' },
  { name: 'country', value: 'Guatemala', code: 'GT' },
  { name: 'country', value: 'Guernsey', code: 'GG' },
  { name: 'country', value: 'Guinea', code: 'GN' },
  { name: 'country', value: 'Guinea-Bissau', code: 'GW' },
  { name: 'country', value: 'Guyana', code: 'GY' },
  { name: 'country', value: 'Haiti', code: 'HT' },
  { name: 'country', value: 'Heard Island and McDonald Islands', code: 'HM' },
  { name: 'country', value: 'Holy See (the)', code: 'VA' },
  { name: 'country', value: 'Honduras', code: 'HN' },
  { name: 'country', value: 'Hong Kong', code: 'HK' },
  { name: 'country', value: 'Hungary', code: 'HU' },
  { name: 'country', value: 'Iceland', code: 'IS' },
  { name: 'country', value: 'India', code: 'IN' },
  { name: 'country', value: 'Indonesia', code: 'In' },
  { name: 'country', value: 'Iran (Islamic Republic of)', code: 'IR' },
  { name: 'country', value: 'Iraq', code: 'IQ' },
  { name: 'country', value: 'Ireland', code: 'IE' },
  { name: 'country', value: 'Isle of Man', code: 'IM' },
  { name: 'country', value: 'Israel', code: 'IL' },
  { name: 'country', value: 'Italy', code: 'IT' },
  { name: 'country', value: 'Jamaica', code: 'JM' },
  { name: 'country', value: 'Japan', code: 'JP' },
  { name: 'country', value: 'Jersey', code: 'JE' },
  { name: 'country', value: 'Jordan', code: 'JO' },
  { name: 'country', value: 'Kazakhstan', code: 'KZ' },
  { name: 'country', value: 'Kenya', code: 'KE' },
  { name: 'country', value: 'Kiribati', code: 'KI' },
  { name: 'country', value: "Korea (the Democratic People's Republic of)", code: 'KP' },
  { name: 'country', value: 'Korea (the Republic of)', code: 'KR' },
  { name: 'country', value: 'Kuwait', code: 'KW' },
  { name: 'country', value: 'Kyrgyzstan', code: 'KG' },
  { name: 'country', value: "Lao People's Democratic Republic (the)", code: 'LA' },
  { name: 'country', value: 'Latvia', code: 'LV' },
  { name: 'country', value: 'Lebanon', code: 'LB' },
  { name: 'country', value: 'Lesotho', code: 'LS' },
  { name: 'country', value: 'Liberia', code: 'LR' },
  { name: 'country', value: 'Libya', code: 'LY' },
  { name: 'country', value: 'Liechtenstein', code: 'LI' },
  { name: 'country', value: 'Lithuania', code: 'LT' },
  { name: 'country', value: 'Luxembourg', code: 'LU' },
  { name: 'country', value: 'Macao', code: 'MO' },
  { name: 'country', value: 'Madagascar', code: 'MG' },
  { name: 'country', value: 'Malawi', code: 'MW' },
  { name: 'country', value: 'Malaysia', code: 'MY' },
  { name: 'country', value: 'Maldives', code: 'MV' },
  { name: 'country', value: 'Mali', code: 'ML' },
  { name: 'country', value: 'Malta', code: 'MT' },
  { name: 'country', value: 'Marshall Islands (the)', code: 'MH' },
  { name: 'country', value: 'Martinique', code: 'MQ' },
  { name: 'country', value: 'Mauritania', code: 'MR' },
  { name: 'country', value: 'Mauritius', code: 'MU' },
  { name: 'country', value: 'Mayotte', code: 'YT' },
  { name: 'country', value: 'Mexico', code: 'MX' },
  { name: 'country', value: 'Micronesia (Federated States of)', code: 'FM' },
  { name: 'country', value: 'Moldova (the Republic of)', code: 'MD' },
  { name: 'country', value: 'Monaco', code: 'MC' },
  { name: 'country', value: 'Mongolia', code: 'MN' },
  { name: 'country', value: 'Montenegro', code: 'ME' },
  { name: 'country', value: 'Montserrat', code: 'MS' },
  { name: 'country', value: 'Morocco', code: 'MA' },
  { name: 'country', value: 'Mozambique', code: 'MZ' },
  { name: 'country', value: 'Myanmar', code: 'MM' },
  { name: 'country', value: 'Namibia', code: 'NA' },
  { name: 'country', value: 'Nauru', code: 'NR' },
  { name: 'country', value: 'Nepal', code: 'NP' },
  { name: 'country', value: 'Netherlands (the)', code: 'NL' },
  { name: 'country', value: 'New Caledonia', code: 'NC' },
  { name: 'country', value: 'New Zealand', code: 'NZ' },
  { name: 'country', value: 'Nicaragua', code: 'NI' },
  { name: 'country', value: 'Niger (the)', code: 'NE' },
  { name: 'country', value: 'Nigeria', code: 'NG' },
  { name: 'country', value: 'Niue', code: 'NU' },
  { name: 'country', value: 'Norfolk Island', code: 'NF' },
  { name: 'country', value: 'Northern Mariana Islands (the)', code: 'MP' },
  { name: 'country', value: 'Norway', code: 'NO' },
  { name: 'country', value: 'Oman', code: 'OM' },
  { name: 'country', value: 'Pakistan', code: 'PK' },
  { name: 'country', value: 'Palau', code: 'PW' },
  { name: 'country', value: 'Palestine, State of', code: 'PS' },
  { name: 'country', value: 'Panama', code: 'PA' },
  { name: 'country', value: 'Papua New Guinea', code: 'PG' },
  { name: 'country', value: 'Paraguay', code: 'PY' },
  { name: 'country', value: 'Peru', code: 'PE' },
  { name: 'country', value: 'Philippines (the)', code: 'PH' },
  { name: 'country', value: 'Pitcairn', code: 'PN' },
  { name: 'country', value: 'Poland', code: 'PL' },
  { name: 'country', value: 'Portugal', code: 'PT' },
  { name: 'country', value: 'Puerto Rico', code: 'PR' },
  { name: 'country', value: 'Qatar', code: 'QA' },
  { name: 'country', value: 'Republic of North Macedonia', code: 'MK' },
  { name: 'country', value: 'Romania', code: 'RO' },
  { name: 'country', value: 'Russian Federation (the)', code: 'RU' },
  { name: 'country', value: 'Rwanda', code: 'RW' },
  { name: 'country', value: 'Réunion', code: 'RE' },
  { name: 'country', value: 'Saint Barthélemy', code: 'BL' },
  { name: 'country', value: 'Saint Helena, Ascension and Tristan da Cunha', code: 'SH' },
  { name: 'country', value: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'country', value: 'Saint Lucia', code: 'LC' },
  { name: 'country', value: 'Saint Martin (French part)', code: 'MF' },
  { name: 'country', value: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'country', value: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'country', value: 'Samoa', code: 'WS' },
  { name: 'country', value: 'San Marino', code: 'SM' },
  { name: 'country', value: 'Sao Tome and Principe', code: 'ST' },
  { name: 'country', value: 'Saudi Arabia', code: 'SA' },
  { name: 'country', value: 'Senegal', code: 'SN' },
  { name: 'country', value: 'Serbia', code: 'RS' },
  { name: 'country', value: 'Seychelles', code: 'SC' },
  { name: 'country', value: 'Sierra Leone', code: 'SL' },
  { name: 'country', value: 'Singapore', code: 'SG' },
  { name: 'country', value: 'Sint Maarten (Dutch part)', code: 'SX' },
  { name: 'country', value: 'Slovakia', code: 'SK' },
  { name: 'country', value: 'Slovenia', code: 'SI' },
  { name: 'country', value: 'Solomon Islands', code: 'SB' },
  { name: 'country', value: 'Somalia', code: 'SO' },
  { name: 'country', value: 'South Africa', code: 'ZA' },
  { name: 'country', value: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'country', value: 'South Sudan', code: 'SS' },
  { name: 'country', value: 'Spain', code: 'ES' },
  { name: 'country', value: 'Sri Lanka', code: 'LK' },
  { name: 'country', value: 'Sudan (the)', code: 'SD' },
  { name: 'country', value: 'Surname', code: 'SR' },
  { name: 'country', value: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'country', value: 'Sweden', code: 'SE' },
  { name: 'country', value: 'Switzerland', code: 'CH' },
  { name: 'country', value: 'Syrian Arab Republic', code: 'SY' },
  { name: 'country', value: 'Taiwan (Province of China)', code: 'TW' },
  { name: 'country', value: 'Tajikistan', code: 'TJ' },
  { name: 'country', value: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'country', value: 'Thailand', code: 'TH' },
  { name: 'country', value: 'Timor-Leste', code: 'TL' },
  { name: 'country', value: 'Togo', code: 'TG' },
  { name: 'country', value: 'Tokelau', code: 'TK' },
  { name: 'country', value: 'Tonga', code: 'TO' },
  { name: 'country', value: 'Trinnd Tobago', code: 'TT' },
  { name: 'country', value: 'Tunisia', code: 'TN' },
  { name: 'country', value: 'Turkey', code: 'TR' },
  { name: 'country', value: 'Turkmenistan', code: 'TM' },
  { name: 'country', value: 'Turks and Caicos Islands (the)', code: 'TC' },
  { name: 'country', value: 'Tuvalu', code: 'TV' },
  { name: 'country', value: 'Uganda', code: 'UG' },
  { name: 'country', value: 'Ukraine', code: 'UA' },
  { name: 'country', value: 'United Arab Emirates (the)', code: 'AE' },
  {
    name: 'country',
    value: 'United Kingdom of Great Britain and Northern Ireland (the)',
    code: 'GB'
  },
  { name: 'country', value: 'United States Minor Outlying Islands (the)', code: 'UM' },
  { name: 'country', value: 'United States of America (the)', code: 'US' },
  { name: 'country', value: 'Uruguay', code: 'UY' },
  { name: 'country', value: 'Uzbekistan', code: 'UZ' },
  { name: 'country', value: 'Vanuatu', code: 'VU' },
  { name: 'country', value: 'Venezuela (Bolivarian Republic of)', code: 'VE' },
  { name: 'country', value: 'Viet Nam', code: 'VN' },
  { name: 'country', value: 'Virgin Islands (British)', code: 'VG' },
  { name: 'country', value: 'Virgin Islands (U.S.)', code: 'VI' },
  { name: 'country', value: 'Wallis and Futuna', code: 'WF' },
  { name: 'country', value: 'Western Sahara', code: 'EH' },
  { name: 'country', value: 'Yemen', code: 'YE' },
  { name: 'country', value: 'Zambia', code: 'ZM' },
  { name: 'country', value: 'Zimbabwe', code: 'ZW' }
];

export const studyLevelOptions = [
  {
    value: 'Primario Completo',
    name: 'studyLevel'
  },
  {
    value: 'Secundario Completo',
    name: 'studyLevel'
  },
  {
    value: 'Terciario Incompleto',
    name: 'studyLevel'
  },
  {
    value: 'Terciario Completo',
    name: 'studyLevel'
  },
  {
    value: 'Universitario Incompleto',
    name: 'studyLevel'
  },
  {
    value: 'Universitario Completo',
    name: 'studyLevel'
  },
  {
    value: 'Posgrado Incompleto',
    name: 'studyLevel'
  },
  {
    value: 'Posgrado Completo',
    name: 'studyLevel'
  },
  {
    value: 'Maestría Incompleta',
    name: 'studyLevel'
  },
  {
    value: 'Maestría Completa',
    name: 'studyLevel'
  },
  {
    value: 'Doctorado',
    name: 'studyLevel'
  },
  {
    value: 'Otro',
    name: 'studyLevel'
  }
];

export const workFieldOptions = [
  { value: 'Administración', name: 'workField' },
  { value: 'Arquitectura', name: 'workField' },
  { value: 'Banca', name: 'workField' },
  { value: 'Biotecnología', name: 'workField' },
  { value: 'Ciencia Política', name: 'workField' },
  { value: 'Comercio Internacional', name: 'workField' },
  { value: 'Comunicación', name: 'workField' },
  { value: 'Construcción', name: 'workField' },
  { value: 'Derecho', name: 'workField' },
  { value: 'Economía', name: 'workField' },
  { value: 'Educación', name: 'workField' },
  { value: 'Ingeniería', name: 'workField' },
  { value: 'Ingeniería Civil', name: 'workField' },
  { value: 'Ingeniería de Sistemas', name: 'workField' },
  { value: 'Ingeniería de Telecomunicaciones', name: 'workField' },
  { value: 'Ingeniería Eléctrica', name: 'workField' },
  { value: 'Ingeniería Electrónica', name: 'workField' }
];

export const jobTitleOptions = [
  { value: 'Director/Consejo de Administración', name: 'jobTitle', checked: true },
  { value: 'CEO', name: 'jobTitle' },
  { value: 'Vicepresidente', name: 'jobTitle' },
  { value: 'Director de Área', name: 'jobTitle' },
  { value: 'Gerente', name: 'jobTitle' },
  { value: 'Jefe', name: 'jobTitle' },
  { value: 'Supervisor', name: 'jobTitle' },
  { value: 'Capataz', name: 'jobTitle' },
  { value: 'Asistente', name: 'jobTitle' },
  { value: 'Analista', name: 'jobTitle' },
  { value: 'Operario', name: 'jobTitle' },
  { value: 'Ordenanza', name: 'jobTitle' }
];

export const jobAreaOptions = [
  { value: 'Administración/Contabilidad', name: 'jobArea' },
  { value: 'Finanzas', name: 'jobArea' },
  { value: 'Control de Gestión', name: 'jobArea' },
  { value: 'Tecnología', name: 'jobArea' },
  { value: 'Marketing', name: 'jobArea' },
  { value: 'Ventas', name: 'jobArea' },
  { value: 'Comunicación', name: 'jobArea' },
  { value: 'Consultoría', name: 'jobArea' },
  { value: 'Atención al cliente', name: 'jobArea' },
  { value: 'RSE', name: 'jobArea' },
  { value: 'Servicios pre-pos venta', name: 'jobArea' },
  { value: 'People', name: 'jobArea' },
  { value: 'Supply', name: 'jobArea' },
  { value: 'Producto', name: 'jobArea' },
  { value: 'Manufactura', name: 'jobArea' },
  { value: 'Otra', name: 'jobArea' }
];

export const yearsInJobOptions = [
  { value: 'Menos de 1 año', name: 'yearsInJob' },
  { value: '1 a 3 años', name: 'yearsInJob' },
  { value: '4 a 5 años', name: 'yearsInJob' },
  { value: '5 a 10 años', name: 'yearsInJob' },
  { value: 'Mas de 10 años', name: 'yearsInJob' }
];

export const yesNoOptions = [
  { value: 'Sí', name: 'peopleInCharge' },
  { value: 'No', name: 'peopleInCharge' }
];

export const yesNoOptionsDisability = [
  { value: 'sí', name: 'disability' },
  { value: 'no', name: 'disability' }
];

export function generateArrayOfYears() {
  let max = new Date().getFullYear() - 18;
  let min = max - 99;
  let years = [];

  for (let i = max; i >= min; i--) {
    years.push({
      value: i,
      name: i
    });
  }
  return years;
}

export const birthYearOptions = generateArrayOfYears();

export const howManyPeopleOptions = [
  { value: '1 a 10', name: 'howManyPeople' },
  { value: '11 a 25', name: 'howManyPeople' },
  { value: '26 a 50', name: 'howManyPeople' },
  { value: '51 a 100', name: 'howManyPeople' },
  { value: 'más de 100', name: 'howManyPeople' }
];

export const disabilityOptions = [
  { value: 'Visual', name: 'disabilityType' },
  { value: 'Auditiva', name: 'disabilityType' },
  { value: 'Motriz', name: 'disabilityType' },
  { value: 'otra', name: 'disabilityType' }
];
