export const cards = [
  {
    title: 'Elecciones automáticas',
    text: 'Tal como lo indica su nombre este juego muestra cómo respondemos y cuáles son las elecciones que hacemos de forma automática.'
  },
  {
    title: 'Creencias',
    text: 'Este tipo de respuesta muestra la forma en la que está modelado nuestro cerebro de acuerdo a las creencias profundas que adquirimos a lo largo de nuestra vida y que fueron creando en nuestra mente estereotipos culturales válidos.'
  },
  {
    title: 'Decisiones',
    text: 'Si tuviésemos la oportunidad de pensar, tal como podemos hacerlo en el día a día, quizá forzaríamos una elección distinta a nuestra respuesta automática debido a ciertas variables que podemos pensar y aislar de forma consciente para la toma de decisión. Esa es la magia del juego de respuesta automática: “Ser conscientes de cuáles son nuestras respuestas automáticas para estar atentos y ejercitar cambiarlas”'
  }
];

export const tooltips = [
  {
    title: 'Fuerza',
    text: 'Cultura poco aprendiente, de baja escucha e incorporación de lo nuevo y diferente, sean innovaciones o habilidades personales.'
  },
  {
    title: 'Apertura',
    text: 'Es una cultura que aprende pero les cuesta mirar el pasado. Están centrados en la juventud, son libres y con poder sobre sí mismos. Les cuesta rendir cuentas. Pueden no valorar la experiencia.'
  },
  {
    title: 'Tech',
    text: 'Refuerza al grupo Apertura aunque es menos rígida  porque cree en la flexibilidad y en la adaptación. Este tipo de cultura va generando paso a paso, creen en el life balance y se toman el tiempo para crear. Todos se sienten pares, las jerarquías no se notan demasiado. Aman la disrupción. '
  },
  {
    title: 'Piedra',
    text: 'Cultura de otro tiempo en la que el management tiene una mirada puesta en el corto plazo, donde las jerarquías se valoran y mantienen el  status quo que da lugar al micromanagement. Una estructura pesada de mover en la que cualquier cambio es difícil de implementar y que no empodera a los colaboradores. Verticalista.'
  },
  {
    title: 'NFT',
    text: 'En este tipo de cultura la apertura a la diversidad es total, se construye  a través de la diversidad. Puede ser demasiado democrática y  tornarse burocrática y poco ágil. Es la cultura del impacto social. '
  }
];

export const statsQustions = [
  '¿Tiene sentido para tÍ el resultado del juego de Respuesta Automática?',
  '¿Cuál es tu primera reacción frente al resultado Efecto Respuesta Automática?',
  '¿Qué reflexiones podrías hacer respecto a los diferentes resultados de las categorías del juego?',
  'Respecto al juego de palabras, ¿Qué aspectos de la cultura de tu organización tal como la ves cambiarías?',
  '¿Crees que tanto el resultado del juego de respuesta automática como el de palabras afecta tu forma de hacer y/o liderar en el día a día en la organización?',
  '¿Qué acciones concretas podrías realizar para cambiar el resultado en el futuro?'
];

export const recommendedReads = [
  '¿Cómo democratizar la Diversidad e Inclusión en una compañia?',
  'Cómo Crear Un Programa De D&I Eficaz Y Duradero'
];
