import React from 'react';
import logo from '../assets/logo.png';

const ErrorView = () => {
  return (
    <div className="h-screen w-screen grid place-items-center">
      <div>
        <img src={logo} alt="logo" className="w-60 mx-auto" draggable={false} />
        <p className="mt-6 text-center text-lg sm:text-xl">
          Contacta a tu administrador. Hubo un error con este juego
        </p>
      </div>
    </div>
  );
};

export default ErrorView;
