import React from 'react';
import { getNotificationClasses, getNotificationIcons } from '../helpers/getClasses';

export const NotificationType = {
  ERROR: 'error',
  SUCCESS: 'success'
};

// eslint-disable-next-line react/prop-types
const Notification = ({ message, type }) => {
  return (
    <div
      className={` ${getNotificationClasses(
        type
      )} border-2 rounded-full text-md p-4 flex justify-between`}>
      <div>
        <div className="flex items-center">
          <img
            className="mr-4"
            width={25}
            height={25}
            src={getNotificationIcons(type)}
            alt={type}
          />

          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default Notification;
