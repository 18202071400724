import PropTypes from 'prop-types';

import figureA1 from '../assets/figureA1.svg';
import figureA2 from '../assets/figureA2.svg';
import figureB1 from '../assets/figureB1.svg';
import figureB2 from '../assets/figureB2.svg';

const LayoutDecorativeFigure = ({ children }) => {
  return (
    <div className="flex flex-col rounded-lg gap-x-8 md:flex-row">
      <div className="absolute left-0 top-10 flex flex-col w-16 md:top-64 md:w-56">
        <img src={figureA1} alt="decorative figure" className="self-end w-3/4" draggable="false" />
        <img src={figureA2} alt="decorative figure" className="pt-2 w-full" draggable="false" />
      </div>
      {children}
      <div className="absolute right-0 top-10 flex items-end flex-col pl-3 w-16 md:pl-10 md:top-64 md:w-56">
        <img src={figureB1} alt="decorative figure" className="w-full mr-0" draggable="false" />
        <img
          src={figureB2}
          alt="decorative figure"
          className="self-start pt-2 w-3/4 md:w-1/2"
          draggable="false"
        />
      </div>
    </div>
  );
};

LayoutDecorativeFigure.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayoutDecorativeFigure;
